import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

import {CollaborativeEntity} from '../entity';
import {Member} from './members.model';

export const addMemberIDs = createAction(
  '[Member/API] Add Member IDs',
  props<{ids: string[]}>()
);

export const addMember = createAction(
  '[Member/API] Add Member',
  props<{member: Member}>()
);

export const setMember = createAction(
  '[Member/API] Set Member',
  props<{member: Member}>()
);

export const upsertMember = createAction(
  '[Member/API] Upsert Member',
  props<{member: Member}>()
);

export const addMembers = createAction(
  '[Member/API] Add Memberss',
  props<{members: Member[]}>()
);

export const upsertMembers = createAction(
  '[Member/API] Upsert Memberss',
  props<{members: Member[]}>()
);

export const updateMember = createAction(
  '[Member/API] Update Member',
  props<{member: Update<Member>}>()
);

export const updateMembers = createAction(
  '[Member/API] Update Memberss',
  props<{members: Update<Member>[]}>()
);

export const mapMember = createAction(
  '[Member/API] Map Member',
  props<{entityMap: EntityMapOne<Member>}>()
);

export const mapMembers = createAction(
  '[Member/API] Map Memberss',
  props<{entityMap: EntityMap<Member>}>()
);

export const deleteMember = createAction(
  '[Member/API] Delete Member',
  props<{id: string}>()
);

export const deleteMembersFromfirestore = createAction(
  '[eMembers/API] Delete eMembers From Firestore',
  props<{id: string}>()
);

export const deleteMembers = createAction(
  '[Member/API] Delete Memberss',
  props<{ids: string[]}>()
);

export const loadMembers = createAction(
  '[Member/API] Load Memberss',
  props<{members: Member[]}>()
);

export const setMembers = createAction(
  '[Member/API] Set Memberss',
  props<{members: Member[]}>()
);

export const clearMembers = createAction('[Member/API] Clear Memberss');

export const selectMembersID = createAction(
  '[Member/API] Select Member',
  props<{id: string}>()
);

export const updateFirestorePartialMember = createAction(
  '[Member/API] Save Partial Member',
  props<{changes: Partial<Member>; members: Member}>()
);

export const updateMemberEntity = createAction(
  '[Member/API] Update Member BaseEntity',
  props<{entity: CollaborativeEntity}>()
);
