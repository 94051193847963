import {ngPatLogout} from '@ngpat/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {resetNavigation} from '../+ui/ui.actions';
import * as QuizActions from './quiz.actions';
import {Quiz} from './quiz.model';

export const quizFeatureKey = 'quiz';

export interface QuizState extends EntityState<Quiz> {
  // additional entities state properties
  selectedQuizTestID: string | null;
}

export interface PartialQuizState {
  readonly [quizFeatureKey]: QuizState;
}

export const quizAdapter: EntityAdapter<Quiz> = createEntityAdapter<Quiz>();

export const initialQuizState: QuizState = quizAdapter.getInitialState({
  // additional entity state properties
  selectedQuizTestID: null
});

export const reducer = createReducer(
  initialQuizState,
  on(QuizActions.addQuiz, (state, action) =>
    quizAdapter.addOne(action.quiz, state)
  ),
  on(QuizActions.setQuiz, (state, action) =>
    quizAdapter.setOne(action.quiz, state)
  ),
  on(QuizActions.addQuizs, (state, action) =>
    quizAdapter.addMany(action.quizs, state)
  ),
  on(QuizActions.updateQuiz, (state, action) =>
    quizAdapter.updateOne(action.quiz, state)
  ),
  on(QuizActions.updateQuizs, (state, action) =>
    quizAdapter.updateMany(action.quizs, state)
  ),
  on(QuizActions.upsertQuiz, (state, action) =>
    quizAdapter.upsertOne(action.quiz, state)
  ),
  on(QuizActions.upsertQuizs, (state, action) =>
    quizAdapter.upsertMany(action.quizs, state)
  ),
  on(QuizActions.mapQuiz, (state, {entityMap}) => {
    return quizAdapter.mapOne(entityMap, state);
  }),
  on(QuizActions.mapQuizs, (state, {entityMap}) => {
    return quizAdapter.map(entityMap, state);
  }),
  on(QuizActions.deleteQuiz, (state, action) =>
    quizAdapter.removeOne(action.id, state)
  ),
  on(QuizActions.deleteQuizs, (state, action) =>
    quizAdapter.removeMany(action.ids, state)
  ),
  on(QuizActions.loadQuizs, (state, action) =>
    quizAdapter.setAll(action.quizs, state)
  ),
  on(QuizActions.setQuizs, (state, action) =>
    quizAdapter.setMany(action.quizs, state)
  ),
  on(QuizActions.clearQuizs, state => quizAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialQuizState,
    ...quizAdapter.removeAll(state)
  })),
  on(QuizActions.selectQuizID, (state, action) => {
    return {
      ...state,
      selectedQuizID: action.id
    };
  }),
  on(resetNavigation, state => {
    return {
      ...state,
      selectedQuizID: null
    };
  })
);
