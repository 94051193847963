import {Dictionary} from '@ngrx/entity';
import {createSelector} from '@ngrx/store';
import {NgPatAccountRemoteConfigs} from '../+account/account.model';
import {selectNgPatAccountRemoteConfigs} from '../+account/account.selectors';
import {selectNgPatIsMobile} from '../+device/index';
import {
  NgPatAccessConfig,
  NgPatRemoteConfigEntity,
  selectNgPatRemoteConfigEntities
} from '../+remote-config/index';
import {
  selectAllIapTransformedProducts,
  selectNgPatHasActiveMobileSubscription
} from './+in_app_purchase/in-app-purchase.selectors';
import {
  selectAllStripeTransformedProducts,
  selectNgPatHasActiveStripeSubscription
} from './+stripe/index';
import {NgPatNormalizedPrice} from './normalized-price.model';

export const selectAllProductsWithPrices = createSelector(
  selectAllIapTransformedProducts,
  selectAllStripeTransformedProducts,
  selectNgPatIsMobile,
  (
    iapProducts: NgPatNormalizedPrice[],
    webProducts: NgPatNormalizedPrice[],
    isNativePlatform: boolean
  ) => {
    // console.log(webProducts);

    if (isNativePlatform) {
      return iapProducts;
    }
    return webProducts;
  }
);

export const selectHasRemoteConfigAccessCodeAssignedToAccount = createSelector(
  selectNgPatRemoteConfigEntities,
  selectNgPatAccountRemoteConfigs,
  (rc: Dictionary<NgPatRemoteConfigEntity>, account: NgPatAccountRemoteConfigs): boolean => {
    // Does account have a remote config code assigned to it?
    if (account && Object.keys(account).length > 0) {
      return Object.keys(account).some(key => {
        if (rc[key] !== undefined && (<NgPatRemoteConfigEntity>rc[key])?.id === key) {
          const remoteConfig: NgPatAccessConfig = (<NgPatRemoteConfigEntity>rc[key]).value;
          if (remoteConfig && (<NgPatAccessConfig>remoteConfig)['type'] === 'access') {
            return <boolean>remoteConfig['permissions']['all'];
          }
        }

        return false;
      });
    }

    return false;
  }
);

export const selectNagPatHasActiveSubscription = createSelector(
  selectNgPatHasActiveStripeSubscription,
  selectNgPatHasActiveMobileSubscription,
  selectHasRemoteConfigAccessCodeAssignedToAccount,
  (
    hasActiveStribeSubscription: boolean,
    hasMobileSubscription: boolean,
    hasRemoteConfig: boolean
  ) => {
    return (hasActiveStribeSubscription || hasMobileSubscription || hasRemoteConfig
    );
  }
);

export const selectNgPatNotHasActiveSubscription = createSelector(
  selectNagPatHasActiveSubscription,
  hasActiveSubscription => !hasActiveSubscription
);
