import {BaseEntity} from '../../entity';
import {firestoreQueryPathByEntity} from './aggregate';
import {BASE_COLLECTION_NAME} from './model';

export function firestoreQuizQuestionsByEntity(p: BaseEntity, uid: string) {
  return `${firestoreQueryPathByEntity(p, uid)}/${BASE_COLLECTION_NAME.QUESTIONS}`;
}

export function firestoreQuizQuestionByEntityAndQuestionId(
  p: BaseEntity,
  questionID: string,
  uid: string
) {
  return `${firestoreQueryPathByEntity(p, uid)}/${BASE_COLLECTION_NAME.QUESTIONS}/${questionID}`;
}

export function firestoreQuizQuestionsAnswersByEntity(
  p: BaseEntity,
  uid: string,
  questionId: string
) {
  return `${firestoreQueryPathByEntity(p, uid)}/${BASE_COLLECTION_NAME.QUESTIONS}/${questionId}/${BASE_COLLECTION_NAME.ANSWERS}`;
}
