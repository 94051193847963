import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  Input,
  ViewEncapsulation
} from '@angular/core';
import {ThemePalette} from '../../core/common-behaviors/color';
import {DlcAutoResizeTextAreaDirective} from './auto-resize-textarea-element';
import {AutoResizeTextareaControlsComponent} from './controls/auto-resize-textarea-controls.component';

@Component({
    selector: 'dlc-auto-resize-textarea',
    // standalone: true,
    templateUrl: './auto-resize-textarea.component.html',
    styleUrls: ['./auto-resize-textarea.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-auto-resize-textarea',
        '[class.with-controls]': 'withControls',
        '[class.dlc-focused]': 'isFocused()',
        '[class.dlc-primary]': 'color !== "accent" && color !== "warn"',
        '[class.dlc-accent]': 'color === "accent"',
        '[class.dlc-warn]': 'color === "warn"'
    },
    standalone: false
})
export class AutoResizeTextareaComponent implements AfterContentChecked {
  @ContentChild(AutoResizeTextareaControlsComponent, {static: true})
  controls!: AutoResizeTextareaControlsComponent;

  @ContentChild(DlcAutoResizeTextAreaDirective, {static: true})
  textarea!: DlcAutoResizeTextAreaDirective;

  @Input() withControls = false;

  @Input() color: ThemePalette = 'primary';

  private _boundTextArea: DlcAutoResizeTextAreaDirective | null = null;
  private set boundTextArea(textarea: DlcAutoResizeTextAreaDirective | null) {
    this._boundTextArea = textarea;
  }

  isFocused = computed(() => {
    const textarea: DlcAutoResizeTextAreaDirective | null = this._boundTextArea;

    if (textarea) {
      return textarea.isFocusedSignal();
    }

    return false;
  });

  ngAfterContentChecked() {
    if (this.controls) {
      this.withControls = true;
    }

    if (this.textarea && !this._boundTextArea) {
      this.boundTextArea = this.textarea;
    }
  }
}
