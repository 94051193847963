import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

import {Project} from './project.model';

export const addProject = createAction(
  '[Project/API] Add Project',
  props<{project: Project}>()
);

export const setProject = createAction(
  '[Project/API] Set Project',
  props<{project: Project}>()
);

export const upsertProject = createAction(
  '[Project/API] Upsert Project',
  props<{project: Project}>()
);

export const addProjects = createAction(
  '[Project/API] Add Projects',
  props<{projects: Project[]}>()
);

export const upsertProjects = createAction(
  '[Project/API] Upsert Projects',
  props<{projects: Project[]}>()
);

export const updateProject = createAction(
  '[Project/API] Update Project',
  props<{project: Update<Project>}>()
);

export const updateProjectEffect = createAction(
  '[Project/API] Update Project Effect',
  props<{project: Project}>()
);

export const updateProjects = createAction(
  '[Project/API] Update Projects',
  props<{projects: Update<Project>[]}>()
);

export const mapProject = createAction(
  '[Project/API] Map Project',
  props<{entityMap: EntityMapOne<Project>}>()
);

export const mapProjects = createAction(
  '[Project/API] Map Projects',
  props<{entityMap: EntityMap<Project>}>()
);

export const deleteProject = createAction(
  '[Project/API] Delete Project',
  props<{id: string}>()
);

export const deleteProjects = createAction(
  '[Project/API] Delete Projects',
  props<{ids: string[]}>()
);
export const deleteProjectFromfirestore = createAction(
  '[Project/API] Delete Study Group From Firestore',
  props<{id: string}>()
);

export const updateFirestorePartialProject = createAction(
  '[Project/API] Save Partial Project',
  props<{changes: Partial<Project>; project: Project}>()
);

export const loadProjects = createAction(
  '[Project/API] Load Projects',
  props<{projects: Project[]}>()
);

export const setProjects = createAction(
  '[Project/API] Set Projects',
  props<{projects: Project[]}>()
);

export const clearProjects = createAction('[Project/API] Clear Projects');

export const selectProjectID = createAction(
  '[Project/API] Select Project',
  props<{id: string}>()
);
