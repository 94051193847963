import {NgPatFirestoreService} from '@ngpat/firebase';
import {shuffle, uuid} from '@ngpat/fn';
import {
  NgPatAccountState,
  NgPatEntityStore,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {select, Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';

import {selectBaseEntityByType} from '../../common.selectors';
import {BaseEntity} from '../../entity/index';
import {firestoreQuizGradeByEntity} from '../../firebase/index';
import {createInitialQuizResult, createTestByQuiz} from '../quiz.fns';
import {
  Question,
  QuestionWithAnswer,
  Quiz,
  TakeQuizAggregateMultiChoiceAnswers,
  TakeQuizResult
} from '../quiz.model';
import {
  aggregateAnswers,
  calculateProgressBasedOnTakeQuizResult,
  cloneInitialQuizTestProgress,
  QuizTestProgress
} from './quiz-test.fns';

export class QuizTest implements NgPatFirebaseConnectionService {
  private _aggregateMultiChoiceAnswers: TakeQuizAggregateMultiChoiceAnswers = {
    aggregateFalseAnswers: true,
    totalAnswers: 4
  };
  private _timeStart = 0;
  private _user$: BehaviorSubject<NgPatAccountState | null> =
    new BehaviorSubject<NgPatAccountState | null>(null);
  private user$: Observable<NgPatAccountState> = <
    Observable<NgPatAccountState>
  >this._user$.asObservable().pipe(
    filter((user: NgPatAccountState | null) => {
      return user !== null && user !== undefined;
    })
  );
  progress$: BehaviorSubject<QuizTestProgress> = new BehaviorSubject(
    cloneInitialQuizTestProgress()
  );
  averageTimePerQuestionMS$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.averageTimePerQuestionMS)
  );
  averageTimePerQuestionSeconds$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.averageTimePerQuestionSeconds)
  );
  connection!: NgPatServiceConnector;
  connectionKey = uuid();
  currentPctScore$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.currentPctScore)
  );
  queue: NgPatEntityStore<QuestionWithAnswer> = new NgPatEntityStore(
    {},
    {
      selectId: (q: QuestionWithAnswer) => q.questionID
    }
  );
  currentQuestion$ = this.queue.selectedEntity$();
  numberAnswered$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.numberAnswered)
  );
  result$: BehaviorSubject<TakeQuizResult> =
    new BehaviorSubject<TakeQuizResult>(createInitialQuizResult());
  pctComplete$: Observable<number> = this.result$.pipe(
    map((result: TakeQuizResult) => {
      const totalAnswers = Object.values(result.questions).length;
      const totalAnswered = Object.values(result.questions).filter(
        (q: QuestionWithAnswer) => q.isAnswered
      ).length;
      return Math.floor((totalAnswered / totalAnswers) * 100);
    })
  );
  pctCorrect$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.pctCorrect)
  );
  pctProgress$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.pctProgress)
  );
  pctWrong$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.pctWrong)
  );
  resultLast$: Observable<TakeQuizResult> = this.result$.pipe(take(1));
  selectIsLastEntitySelected$ = this.queue.selectIsLastEntitySelected$();
  selectIsLastEntitySelectedLast$ = this.selectIsLastEntitySelected$.pipe(
    take(1)
  );
  showTestResults$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  totalCorrect$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.totalCorrect)
  );
  totalQuestions$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.totalQuestions)
  );
  totalWrong$: Observable<number> = this.progress$.pipe(
    map((progress: QuizTestProgress) => progress.totalWrong)
  );

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService,
    public quiz: Quiz,
    public questions: Question[]
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);

    this.initialize.call(this);
  }

  private _startTime() {
    this._timeStart = Date.now().valueOf();
  }

  private _stopTime() {
    return Date.now().valueOf() - this._timeStart;
  }

  private getResultEntity(): Observable<Quiz> {
    if (
      this.quiz.parentEntityID !== null &&
      this.quiz.parentEntityID !== undefined &&
      this.quiz.parentEntityType !== null &&
      this.quiz.parentEntityType !== undefined
    ) {
      return this.store.pipe(
        select(
          selectBaseEntityByType(
            this.quiz.parentEntityType,
            this.quiz.parentEntityID
          )
        ),
        take(1)
      );
    } else {
      return of(this.quiz);
    }
  }

  private getResultPath(r: TakeQuizResult): Observable<string> {
    return this.user$.pipe(
      withLatestFrom(this.getResultEntity()),
      map(([user, quiz]: [NgPatAccountState, Quiz]) => {
        return firestoreQuizGradeByEntity(
          quiz as BaseEntity,
          <string>user.uid,
          r.id
        );
      })
    );
  }

  createTestByQuiz(): Observable<TakeQuizResult> {
    return this.user$.pipe(
      filter(user => !!user && this.questions.length > 0),
      map((user: NgPatAccountState) => {
        return createTestByQuiz(this.quiz, this.questions, <string>user.uid);
      }),
      take(1),
      switchMap((r: TakeQuizResult) => {
        // TODO change gto saveTest after test component is developed
        return of(r);
        // return this.saveTest(r);
      })
    );
  }

  initialize() {
    const that = this;
    // this.questions.setMany(questions);
    this.createTestByQuiz()
      .pipe(take(1))
      .subscribe((r: TakeQuizResult) => {
        // New version logic in case quizzes where created without
        // this structure
        const aggregateFalseAnswers =
          this.quiz.aggregateFalseAnswers !== undefined &&
          this.quiz.aggregateFalseAnswers !== null
            ? this.quiz.aggregateFalseAnswers
            : this._aggregateMultiChoiceAnswers.aggregateFalseAnswers;

        const totalAnswers =
          this.quiz.totalQuestions !== undefined &&
          this.quiz.totalQuestions !== null
            ? this.quiz.totalQuestions
            : this._aggregateMultiChoiceAnswers.totalAnswers;

        if (aggregateFalseAnswers) {
          aggregateAnswers(Object.values(r.questions), <
            TakeQuizAggregateMultiChoiceAnswers
          >{
            aggregateFalseAnswers,
            totalAnswers
          });
        }

        that.queue.addMany(shuffle(Object.values(r.questions)));

        that.queue.selectFirstIdIfNoIdSelected();

        // that.result.set(r);
        that.result$.next(r);

        that._startTime();
      });
  }

  nextQuestion() {
    this.queue.next();
  }

  onAnswer(e: QuestionWithAnswer) {
    // console.log('onAnswer', e);

    // const r = this.result();

    combineLatest([this.resultLast$, this.selectIsLastEntitySelectedLast$])
      .pipe(take(1))
      .subscribe(([r, isLastEntitySelected]: [TakeQuizResult, boolean]) => {
        if (r) {
          e.timeToAnswerMS = this._stopTime();
          r.questions[e.questionID] = e;
          // this.result.set(r);
          this.result$.next(r);

          this.progress$.next(calculateProgressBasedOnTakeQuizResult(r));

          this.saveTest(this.result$.value).subscribe(() => {
            /* noop */
          });
        }

        if (isLastEntitySelected) {
          // this.showTestResults.set(true);
          this.showTestResults$.next(true);
        } else {
          this.queue.next();
        }
      });
  }

  onConnect(user: NgPatAccountState) {
    this._user$.next(user);
  }

  onDestroy() {
    this.connection.destroy();
  }

  onDisconnect() {
    // TODO should the quiz be paused?
  }

  previousQuestion() {
    this.queue.previous();
  }

  saveTest(r: TakeQuizResult): Observable<TakeQuizResult> {
    return this.getResultPath(r).pipe(
      switchMap((path: string) => {
        return this.customFirestoreService
          .upsertDoc$(path, r)
          .pipe(map(() => r));
      })
    );
  }
}
