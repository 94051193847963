import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {GigaNoteDoc} from '@ngpat/firebase';

@Component({
    selector: 'dlc-delete-note-dialog',
    imports: [CommonModule, MatButtonModule, MatDialogModule],
    templateUrl: './delete-note-dialog.component.html',
    styleUrl: './delete-note-dialog.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteNoteDialogComponent {
  data: GigaNoteDoc = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef<DeleteNoteDialogComponent>);
}
