import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Input,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {DlcRoundedTextIconButtonComponent} from '../dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';

export enum DlcSaveBtnState {
  DISABLED = 'disabled',
  ACTIVE = 'active',
  IN_PROGRESS = 'in-progress'
}

@Component({
    selector: 'dlc-save-button',
    imports: [CommonModule, DlcRoundedTextIconButtonComponent, MatIconModule, MatProgressSpinner],
    templateUrl: './dlc-save-button.component.html',
    styleUrl: './dlc-save-button.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-save-button'
    }
})
export class DlcSaveButtonComponent {
  btnState: WritableSignal<DlcSaveBtnState> = signal(DlcSaveBtnState.DISABLED);

  isDisabled = computed(() => {
    const state = this.btnState();
    return state === DlcSaveBtnState.DISABLED || state === DlcSaveBtnState.IN_PROGRESS;
  });

  saveInProgress = computed(() => {
    return this.btnState() === DlcSaveBtnState.IN_PROGRESS;
  });

  @Input()
  set state(state: DlcSaveBtnState | undefined) {
    if (state) {
      this.btnState.set(state);
    }
  }

  label = input('Save');

  doSave: OutputEmitterRef<boolean> = output();
}
