import {ngPatLogout} from '@ngpat/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {resetNavigation} from '../+ui/ui.actions';
import * as JournalActions from './journal.actions';
import {Journal} from './journal.model';

export const journalFeatureKey = 'journal';

export interface JournalState extends EntityState<Journal> {
  // additional entities state properties
  selectedJournalID: string | null;
}

export interface PartialJournalState {
  readonly [journalFeatureKey]: JournalState;
}

export const journalAdapter: EntityAdapter<Journal> =
  createEntityAdapter<Journal>();

export const initialJournalState: JournalState = journalAdapter.getInitialState(
  {
    // additional entity state properties
    selectedJournalID: null
  }
);

export const reducer = createReducer(
  initialJournalState,
  on(JournalActions.addJournal, (state, action) =>
    journalAdapter.addOne(action.journal, state)
  ),
  on(JournalActions.setJournal, (state, action) =>
    journalAdapter.setOne(action.journal, state)
  ),
  on(JournalActions.addJournals, (state, action) =>
    journalAdapter.addMany(action.journals, state)
  ),
  on(JournalActions.updateJournal, (state, action) =>
    journalAdapter.updateOne(action.journal, state)
  ),
  on(JournalActions.updateJournals, (state, action) =>
    journalAdapter.updateMany(action.journals, state)
  ),
  on(JournalActions.upsertJournal, (state, action) =>
    journalAdapter.upsertOne(action.journal, state)
  ),
  on(JournalActions.upsertJournals, (state, action) =>
    journalAdapter.upsertMany(action.journals, state)
  ),
  on(JournalActions.mapJournal, (state, {entityMap}) => {
    return journalAdapter.mapOne(entityMap, state);
  }),
  on(JournalActions.mapJournals, (state, {entityMap}) => {
    return journalAdapter.map(entityMap, state);
  }),
  on(JournalActions.deleteJournal, (state, action) =>
    journalAdapter.removeOne(action.id, state)
  ),
  on(JournalActions.deleteJournals, (state, action) =>
    journalAdapter.removeMany(action.ids, state)
  ),
  on(JournalActions.loadJournals, (state, action) =>
    journalAdapter.setAll(action.journals, state)
  ),
  on(JournalActions.setJournals, (state, action) =>
    journalAdapter.setMany(action.journals, state)
  ),
  on(JournalActions.clearJournals, state => journalAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialJournalState,
    ...journalAdapter.removeAll(state)
  })),
  on(JournalActions.selectJournalID, (state, action) => {
    return {
      ...state,
      selectedJournalID: action.id
    };
  }),
  on(resetNavigation, (state: JournalState) => {
    return {
      ...state,
      selectedJournalID: null
    };
  })
);
