import {ChangeDetectionStrategy, Component, input, output, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {DlcRoundedTextIconLogsubButtonComponent} from '../dlc-rounded-text-icon-logsub-button/dlc-rounded-text-icon-logsub-button.component';

@Component({
    selector: 'dlc-tile-button',
    imports: [CommonModule, MatIconModule, DlcRoundedTextIconLogsubButtonComponent],
    templateUrl: './tile-button.component.html',
    styleUrl: './tile-button.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-tile-button',
        '[style.width.px]': 'tileSize()',
        '[style.height.px]': 'tileSize()',
        '(click)': 'onClickTile()'
    }
})
export class TileButtonComponent {
  @ViewChild('actionButton', {static: true}) actionButton!: DlcRoundedTextIconLogsubButtonComponent;

  // tile width and height in pixels
  tileSize = input(100);
  iconSize = input(50);
  ctaText = input('');
  ctaIcon = input('');
  fontIcon = input('');
  doAction = output();

  onClickTile() {
    if (this.actionButton) {
      this.actionButton.doClick();
    }
  }
}
