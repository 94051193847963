import {GigaNoteDoc, NgPatFirestoreCollectionQuery, NgPatFirestoreService} from '@ngpat/firebase';
import {
  aggregateUpdates,
  deleteNotes,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector,
  updateNotes,
  upsertNotes
} from '@ngpat/store';
import {Store} from '@ngrx/store';

import {NoteQueryStoreEntity} from './note-query.store.model';

export class NoteQueryEngine implements NgPatFirebaseConnectionService {
  private _noteFirestoreQuery!: NgPatFirestoreCollectionQuery<GigaNoteDoc>;

  id = this.noteQueryStoreEntity.id;

  /**
   * Connect to firestore
   */
  connectionKey = this.noteQueryStoreEntity.firestoreCollectionPath;
  connection!: NgPatServiceConnector;

  constructor(
    private noteQueryStoreEntity: NoteQueryStoreEntity,
    private store: Store,
    private firestore: NgPatFirestoreService
  ) {
    this.connection = new NgPatServiceConnector(this, this.store);
  }

  ngPatOnInit() {
    this._noteFirestoreQuery = new NgPatFirestoreCollectionQuery(
      {
        deleteManyAction: (ids: string[]) => deleteNotes({ids}),
        queryMember: false,
        updateManyAction: (notes: GigaNoteDoc[]) => updateNotes({notes: aggregateUpdates(notes)}),
        upsertManyAction: (notes: GigaNoteDoc[]) => upsertNotes({notes})
      },
      this.store,
      this.firestore
    );
  }

  onConnect(user: NgPatAccountState) {
    this._noteFirestoreQuery.onConnect(this.noteQueryStoreEntity.firestoreCollectionPath);
  }

  onDisconnect(user: NgPatAccountState) {
    if (this._noteFirestoreQuery) {
      this._noteFirestoreQuery.onDisconnect();
    }
  }
}
