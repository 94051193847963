<button
  dlc-rounded-text-icon-button
  color="primary"
  (click)="$event.preventDefault(); $event.stopImmediatePropagation(); doSave.emit(true)"
  [disabled]="isDisabled()">
  @if (saveInProgress()) {
    <mat-progress-spinner
      class="mr-2"
      diameter="16"
      mode="indeterminate"></mat-progress-spinner>
    Saving...
  } @else {
    <mat-icon>save</mat-icon>
    {{ label() }}
  }
</button>
