import {
  NG_PAT_ROLES,
  selectNgPatAddedAndDeletedEntities,
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';

import {
  getMemberListItemsByEntityID,
  selectAllMemberss
} from '../+members/index';
import {convertCollaborativeEntityToMemberUIDEntityID} from '../+members/member.fns';
import {UiState} from '../+ui/ui.model';
import {selectCurrentProjectID, selectUiState} from '../+ui/ui.selectors';
import {getCreatedAtNanoseconds} from '../fns/get-created-at-nanoseconds-prop';
import {isCreatedBy} from '../fns/user.fns';
import {selectHasActiveSubscription} from '../subscription/subscription.selectors';
import {Project} from './project.model';
import * as ProjectReducer from './project.reducer';
import {ProjectState} from './project.reducer';

export const selectProjectState =
  createFeatureSelector<ProjectReducer.ProjectState>(
    ProjectReducer.projectFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  ProjectReducer.projectAdapter.getSelectors();

export const selectAllProjects = createSelector(selectProjectState, selectAll);
export const selectProjectEntities = createSelector(
  selectProjectState,
  selectEntities
);
export const selectProjectIds = createSelector(selectProjectState, selectIds);
export const selectProjectTotal = createSelector(
  selectProjectState,
  selectTotal
);
export const selectedProjectID = createSelector(
  selectProjectState,
  (state: ProjectReducer.ProjectState) => state.selectedProjectID
);

export const selectProjectMemberMap = createSelector(
  selectAllProjects,
  convertCollaborativeEntityToMemberUIDEntityID
);

export const selectedProject = createSelector(
  selectProjectState,
  (state: ProjectState) => {
    if (state.selectedProjectID) {
      return state.entities[state.selectedProjectID];
    } else {
      return null;
    }
  }
);

export const getProjectByID = (projectID: string | null) =>
  createSelector(
    selectProjectState,
    (state: ProjectReducer.ProjectState): Project | undefined => {
      if (projectID && state.entities[projectID]) {
        return state.entities[projectID];
      }

      return undefined;
    }
  );

export const getProjectByTimestamp = (nanoseconds: string | null | undefined) =>
  createSelector(selectAllProjects, (projects: Project[]) => {
    return projects.reduce(
      (found: Project | null | undefined, i: Project | undefined) => {
        if (!found && i && getCreatedAtNanoseconds(i) === nanoseconds) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const getProjectById = (id: string | null | undefined) =>
  createSelector(selectAllProjects, (projects: Project[]) => {
    return projects.reduce(
      (found: Project | null | undefined, i: Project | undefined) => {
        if (!found && i && i.id === id) {
          return i;
        }

        return found;
      },
      null
    );
  });

export const selectProjectsIoOwn = createSelector(
  selectNgPatLoggedInUID,
  selectAllProjects,
  (uid: string | null, projects: Project[]) => {
    if (uid && uid.length) {
      return projects.filter((s: Project) =>
        uid ? isCreatedBy(s, uid) : false
      );
    }

    return [];
  }
);

export const selectCurrentProject = createSelector(
  selectUiState,
  selectProjectEntities,
  (
    state: UiState,
    projectEntities: Dictionary<Project>
  ): Project | null | undefined => {
    if (state.currentProjectID && projectEntities[state.currentProjectID]) {
      return state.currentProjectID
        ? projectEntities[state.currentProjectID]
        : null;
    }
    return null;
  }
);

export const selectCurrentProjectMembers = createSelector(
  selectCurrentProjectID,
  selectHasActiveSubscription,
  selectNgPatMentorAccountsDict,
  selectAllMemberss,
  getMemberListItemsByEntityID
);

export const canInviteToProject = createSelector(
  selectCurrentProject,
  selectNgPatLoggedInUID,
  (
    project: Project | null | undefined,
    loggedInUID: string | null
  ): boolean => {
    if (
      loggedInUID &&
      project &&
      project.members &&
      project.members[loggedInUID]
    ) {
      return project.members[loggedInUID].role === NG_PAT_ROLES.Owner;
    }
    return false;
  }
);

export const selectAddedAndDeletedProjects =
  selectNgPatAddedAndDeletedEntities<Project>(selectProjectEntities);
