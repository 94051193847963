import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

import {Journal} from './journal.model';

export const addJournal = createAction(
  '[Journal/API] Add Journal',
  props<{journal: Journal}>()
);

export const setJournal = createAction(
  '[Journal/API] Set Journal',
  props<{journal: Journal}>()
);

export const upsertJournal = createAction(
  '[Journal/API] Upsert Journal',
  props<{journal: Journal}>()
);

export const addJournals = createAction(
  '[Journal/API] Add Journals',
  props<{journals: Journal[]}>()
);

export const upsertJournals = createAction(
  '[Journal/API] Upsert Journals',
  props<{journals: Journal[]}>()
);

export const updateJournal = createAction(
  '[Journal/API] Update Journal',
  props<{journal: Update<Journal>}>()
);

export const updateJournalEffect = createAction(
  '[Journal/API] Update Journal Effect',
  props<{journal: Journal}>()
);

export const updateJournals = createAction(
  '[Journal/API] Update Journals',
  props<{journals: Update<Journal>[]}>()
);

export const mapJournal = createAction(
  '[Journal/API] Map Journal',
  props<{entityMap: EntityMapOne<Journal>}>()
);

export const mapJournals = createAction(
  '[Journal/API] Map Journals',
  props<{entityMap: EntityMap<Journal>}>()
);

export const deleteJournal = createAction(
  '[Journal/API] Delete Journal',
  props<{id: string}>()
);

export const deleteJournals = createAction(
  '[Journal/API] Delete Journals',
  props<{ids: string[]}>()
);
export const deleteJournalFromfirestore = createAction(
  '[Journal/API] Delete Study Group From Firestore',
  props<{id: string}>()
);

export const updateFirestorePartialJournal = createAction(
  '[Journal/API] Save Partial Journal',
  props<{changes: Partial<Journal>; journal: Journal}>()
);

export const loadJournals = createAction(
  '[Journal/API] Load Journals',
  props<{journals: Journal[]}>()
);

export const setJournals = createAction(
  '[Journal/API] Set Journals',
  props<{journals: Journal[]}>()
);

export const clearJournals = createAction('[Journal/API] Clear Journals');

export const selectJournalID = createAction(
  '[Journal/API] Select Journal',
  props<{id: string}>()
);
