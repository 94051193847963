import {Observable, Subscriber, switchMap} from 'rxjs';
import {map, take} from 'rxjs/operators';

export function ngPatWithLastValueFrom<T, O>(takeObs$: Observable<O>) {
  return (observable: Observable<T>) =>
    new Observable((subscriber: Subscriber<[T, O]>) => {
      // this function will be called each time this
      // Observable is subscribed to.
      const subscription = observable
        .pipe(
          switchMap(streamValue =>
            takeObs$.pipe(
              take(1),
              map(takeValue => {
                return [streamValue, takeValue] as [T, O];
              })
            )
          )
        )
        .subscribe({
          next(value: [T, O]) {
            subscriber.next(value as [T, O]);
            // subscriber.complete();
          },
          error(err) {
            // We need to make sure we're propagating our errors through.
            subscriber.error(err);
          },
          complete() {
            subscriber.complete();
          }
        });

      // Return the finalization logic. This will be invoked when
      // the result errors, completes, or is unsubscribed.
      return () => {
        subscription.unsubscribe();
        // clean up
      };
    });
}
