import {Member} from '../../+members/members.model';
import {createGradeChartData} from '../../+quizzes/quiz.fns';
import {QuizGrade, TakeQuizResult} from '../../+quizzes/quiz.model';

export function highestQuizGradeByStudent(
  m: Member,
  quizResults: TakeQuizResult[]
): QuizGrade<TakeQuizResult> | null {
  const resultsByThisStudent: QuizGrade<TakeQuizResult>[] = quizResults
    .map(createGradeChartData)
    .filter((r: QuizGrade<TakeQuizResult>) => r.rawData.createdByUID === m.uid);

  if (resultsByThisStudent.length) {
    return resultsByThisStudent.reduce(
      (q: QuizGrade<TakeQuizResult> | null, s: QuizGrade<TakeQuizResult>) => {
        if (q && s.totalCorrect > q.totalCorrect) {
          return s;
        }

        return !q ? s : q;
      },
      null
    );
  }

  return null;
}
