import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  input,
  output,
  OutputEmitterRef,
  signal,
  Signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {selectNagPatHasActiveSubscription, selectNgPatIsLoggedIn} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {AppLoginService, LOGSUB_STATE} from '../../../login/index';
import {coerceThemePalette} from '../button.fns';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[dlc-rounded-text-icon-logsub-button], button[dlcRoundedTextIconButton]',
    imports: [CommonModule, MatIconModule],
    templateUrl: './dlc-rounded-text-icon-logsub-button.component.html',
    styleUrls: ['./dlc-rounded-text-icon-logsub-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-rounded-text-icon-logsub-button dlc-rounded-button',
        '[attr.disabled]': `disabled() === true ? '' : null`,
        '[class.dlc-rounded-text-icon-logsub-button-primary]': 'color() === "primary"',
        '[class.dlc-rounded-text-icon-logsub-button-accent]': 'color() === "accent"',
        '[class.dlc-rounded-text-icon-logsub-button-warn]': 'color() === "warn"',
        '[class.dlc-rounded-text-icon-logsub-button-none]': 'color() === "none" || color() === undefined',
        '[class.dlc-rounded-text-icon-logsub-button-vertical]': 'vertical()',
        '[class.dlc-rounded-text-icon-logsub-button-not-logged-in]': 'state() === logsubButtonStateEnum.SHOW_LOGIN',
        '[class.dlc-rounded-text-icon-logsub-button-not-subscribed]': 'state() === logsubButtonStateEnum.SHOW_SUBSCRIBE',
        '(click)': 'doClick()'
    }
})
export class DlcRoundedTextIconLogsubButtonComponent {
  store = inject(Store);
  loginSubscribeService = inject(AppLoginService);

  logsubButtonStateEnum = LOGSUB_STATE;

  disabled = input(null, {transform: coerceBooleanProperty});
  color = input('none', {transform: coerceThemePalette});
  vertical = input(null, {transform: coerceBooleanProperty});
  requireSubscription = input(true, {transform: coerceBooleanProperty});
  subscribeRoute = input('/subscribe');

  state: WritableSignal<LOGSUB_STATE> = <WritableSignal<LOGSUB_STATE>>(
    signal(LOGSUB_STATE.SHOW_DEFAULT)
  );

  isLoggedIn: Signal<boolean> = <Signal<boolean>>this.store.selectSignal(selectNgPatIsLoggedIn);
  hasActiveSubscription: Signal<boolean> = <Signal<boolean>>(
    this.store.selectSignal(selectNagPatHasActiveSubscription)
  );

  doLogin: OutputEmitterRef<boolean> = output();
  doAction: OutputEmitterRef<boolean> = output();

  constructor() {
    effect(
      () => {
        // Set state
        if (!this.isLoggedIn()) {
          this.state.set(LOGSUB_STATE.SHOW_LOGIN);
        } else if (this.requireSubscription() && !this.hasActiveSubscription()) {
          this.state.set(LOGSUB_STATE.SHOW_SUBSCRIBE);
        } else {
          this.state.set(LOGSUB_STATE.SHOW_DEFAULT);
        }
      },
      {allowSignalWrites: true}
    );
  }

  doClick() {
    const state = this.state();

    if (state !== LOGSUB_STATE.SHOW_DEFAULT) {
      this.loginSubscribeService.loginOrSubscribe(state);
    } else {
      this.doAction.emit(true);
    }
  }
}
