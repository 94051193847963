import {
  ChangeDetectionStrategy,
  Component, Input, signal,
  ViewEncapsulation, WritableSignal
} from '@angular/core';
import {CommonModule} from '@angular/common';

/**
 * @description
 * A component that displays grade while the quiz is in progress.
 */
@Component({
    selector: 'ec-current-grade-progress',
    imports: [CommonModule],
    templateUrl: './ec-current-grade-progress.component.html',
    styleUrls: ['./ec-current-grade-progress.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-current-grade-progress ec-quiz',
        '[class.ec-current-grade-progress-disabled]': '_disabled()',
        '[attr.aria-disabled]': '_disabled() || null',
        '[attr.disabled]': '_disabled() || null', // null will remove attribute
    }
})
export class EcCurrentGradeProgressComponent {

  _disabled: WritableSignal<boolean> = signal(false)

  @Input()
  set disabled(value: boolean) {
    this._disabled.set(value);
  }
}
