import {Injectable} from '@angular/core';
import {generate8CharCodeLowercase} from '@ngpat/fn';
import {selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, forkJoin} from 'rxjs';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {
  updateFirestorePartialJournal,
  updateJournalEffect,
  upsertJournals
} from './journal.actions';
import {Journal} from './journal.model';
import {PartialJournalState} from './journal.reducer';
import {JournalService} from './journal.service';

@Injectable({
  providedIn: 'root'
})
export class JournalEffects {
  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _journalService
   */
  // deleteDoc$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(deleteJournalFromfirestore),
  //     withLatestFrom(this.store.select(selectUserAccount)),
  //     switchMap(([action, account]) =>
  //       this.store.pipe(
  //         select(getClassroomByID(action.id)),
  //         switchMap((classRoom: Classroom | undefined) =>
  //           this._journalService
  //             .deleteDoc$(classRoom, <string>account.uid)
  //             .pipe(map(() => deleteJournal({id: action.id})))
  //         )
  //       )
  //     )
  //   )
  // );

  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _journalService
   */
  // deleteDocs$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteJournals),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getClassroomByID(action.ids[0])),
  //           switchMap((classRoom: Classroom | undefined) =>
  //             this._journalService.deleteDocs$(classRoom, action.ids, <string>account.uid)
  //           )
  //         )
  //       )
  //     ),
  //   {dispatch: false}
  // );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateFirestorePartialJournal),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this._journalService.updatePartialFirestore$(
            action.changes,
            action.journal,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  updateJournal$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateJournalEffect),
        switchMap(action => {
          return this._journalService.updateDoc(action.journal);
        })
      ),
    {dispatch: false}
  );

  updateJournalEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateJournalEffect),
        switchMap(action => {
          return this._journalService.updateDoc(action.journal);
        })
      ),
    {dispatch: false}
  );

  upsertJournals$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(upsertJournals),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) => {
          interface JournalUpdate {
            changes: Partial<Journal>;
            journal: Journal;
          }

          const _journalsUpdate: JournalUpdate[] = action.journals.reduce(
            (a: JournalUpdate[], s: Journal) => {
              const changes: Partial<Journal> = {};

              let update = false;

              if (!s.mentorCode) {
                changes.mentorCode = generate8CharCodeLowercase();
                update = true;
              }

              if (!s.teacherCode) {
                changes.teacherCode = generate8CharCodeLowercase();
                update = true;
              }

              if (!s.studentCode) {
                changes.studentCode = generate8CharCodeLowercase();
                update = true;
              }

              if (update) {
                a.push({
                  changes,
                  journal: {...s}
                });
              }

              return a;
            },
            <JournalUpdate[]>[]
          );

          if (_journalsUpdate.length) {
            return forkJoin(
              _journalsUpdate.map((s: JournalUpdate) =>
                this._journalService.updatePartialFirestore$(
                  s.changes,
                  s.journal,
                  account.uid
                )
              )
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialJournalState>,
    private _journalService: JournalService
  ) {}
}
