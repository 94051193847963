import {pipe} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {distinctUntilJsonChangedOperator} from './distinct-until-json-changed-operator';

/**
 * Filters out null and undefined values and returns only populated JSON ojects.
 */
export const jsonPopulated = <T>() =>
  pipe(
    filter((value: T | null | undefined) => value !== null && value !== undefined),
    distinctUntilJsonChangedOperator<T>(),
    map(value => value as T)
  );
