import Dexie, {Table} from 'dexie';

import {UiStorage} from './+ui/ui.model';

export class EcDB extends Dexie {
  ui!: Table<UiStorage, number>;

  constructor() {
    super('ecliveQuery');
    this.version(3).stores({
      ui: 'id'
    });
  }
}

export const db = new EcDB();
