import {DEFAULT_PLATFORM_ENTITY_TYPE_DICT} from '../../entity/index';
import {firestoreClassCollection} from './classes';
import {firestoreStudyGroupCollection} from './study-groups';

export * from './aggregate';
export * from './app';
export * from './chat';
export * from './classes';
export * from './courses';
export * from './investigations';
export * from './journal';
export * from './model';
export * from './notes';
export * from './projects';
export * from './quiz';
export * from './quiz-grades';
export * from './quiz-questions-by-entity';
export * from './research';
export * from './study-groups';
export * from './subjects';
export * from './user';

export function firestoreCollectionByEntityType(entityType: number) {
  if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
    return firestoreClassCollection();
  }

  if (entityType === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id) {
    return firestoreStudyGroupCollection();
  }

  return '';
}
