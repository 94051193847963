import {DocumentData, DocumentReference, DocumentSnapshot, onSnapshot} from 'firebase/firestore';
import {Subject} from 'rxjs';

export class NgPatSnapshot<T> extends Subject<T> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  firebaseUnsubscribe: () => void = () => {};

  constructor(docRef: DocumentReference<DocumentData>) {
    super();

    this.firebaseUnsubscribe = onSnapshot(docRef, (snap: DocumentSnapshot<DocumentData>) => {
      if (snap.data() !== null && snap.data() !== undefined) {
        this.next(<T>snap.data());
      }
    });
  }

  destroy() {
    this.complete();
    this.firebaseUnsubscribe();
  }

  override unsubscribe() {
    super.unsubscribe();
    this.complete();
    this.firebaseUnsubscribe();
  }
}
