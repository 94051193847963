import {
  BaseEntity,
  CreateEntityParams,
  createEntityParamsObject,
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT
} from '../../entity';
import {firestoreClassDoc, firestoreUserClassDoc} from './classes';
import {firestoreCourseDoc, firestoreUserCourseDoc} from './courses';
import {
  firestoreInvestigationDoc,
  firestoreUserInvestigationDoc
} from './investigations';
import {BASE_COLLECTION_NAME} from './model';
import {firestoreQuizDoc, firestoreUserQuizDoc} from './quiz';
import {firestoreResearchDoc, firestoreUserResearchDoc} from './research';
import {
  firestoreStudyGroupDoc,
  firestoreUserStudyGroupDoc
} from './study-groups';

export function firestoreEntitySubPath(
  params: CreateEntityParams,
  entityID: string
) {
  if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id) {
    return firestoreQuizDoc(entityID);
  }
  if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.COURSE.id) {
    return firestoreCourseDoc(entityID);
  }
  if (
    params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.id
  ) {
    return firestoreResearchDoc(entityID);
  }

  if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
    return firestoreClassDoc(entityID);
  }

  if (
    params.entityTypeValue ===
    DEFAULT_PLATFORM_ENTITY_TYPE_DICT.INVESTIGATION.id
  ) {
    return firestoreInvestigationDoc(entityID);
  }

  if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT.id) {
    return firestoreInvestigationDoc(entityID);
  }

  if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.JOURNAL.id) {
    return firestoreInvestigationDoc(entityID);
  }

  return '';
}

/**
 * BaseEntity based on BaseEntityType and Path
 */
export function firestoreEntityPathByParams(
  params: CreateEntityParams,
  uid: string,
  entityID: string
): string {
  if (params.isCollaborative) {
    if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id) {
      return firestoreQuizDoc(entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.COURSE.id
    ) {
      return firestoreCourseDoc(entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.id
    ) {
      return firestoreResearchDoc(entityID);
    }
    if (
      params.entityTypeValue ===
      DEFAULT_PLATFORM_ENTITY_TYPE_DICT.INVESTIGATION.id
    ) {
      return firestoreInvestigationDoc(entityID);
    }
    if (
      params.entityTypeValue ===
      DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id
    ) {
      return firestoreStudyGroupDoc(entityID);
    }
    if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
      return firestoreClassDoc(entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT.id
    ) {
      return firestoreClassDoc(entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.JOURNAL.id
    ) {
      return firestoreClassDoc(entityID);
    }
  } else {
    if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.QUIZ.id) {
      return firestoreUserQuizDoc(uid, entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.COURSE.id
    ) {
      return firestoreUserCourseDoc(uid, entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.RESEARCH.id
    ) {
      return firestoreUserResearchDoc(uid, entityID);
    }
    if (
      params.entityTypeValue ===
      DEFAULT_PLATFORM_ENTITY_TYPE_DICT.INVESTIGATION.id
    ) {
      return firestoreUserInvestigationDoc(uid, entityID);
    }
    if (
      params.entityTypeValue ===
      DEFAULT_PLATFORM_ENTITY_TYPE_DICT.STUDY_GROUP.id
    ) {
      return firestoreUserStudyGroupDoc(uid, entityID);
    }
    if (params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.CLASS.id) {
      return firestoreUserClassDoc(uid, entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.PROJECT.id
    ) {
      return firestoreUserClassDoc(uid, entityID);
    }
    if (
      params.entityTypeValue === DEFAULT_PLATFORM_ENTITY_TYPE_DICT.JOURNAL.id
    ) {
      return firestoreUserClassDoc(uid, entityID);
    }
  }

  return '';
}

export function firestoreQueryPathByEntity(p: BaseEntity, uid: string) {
  const params: CreateEntityParams = createEntityParamsObject(p);

  if (
    p.isCollaborative &&
    p.parentEntityID &&
    p.parentEntityID.length &&
    p.parentEntityType !== null
  ) {
    const parentParams: CreateEntityParams = {
      ...params,
      entityTypeValue: p.parentEntityType
    };

    return `${firestoreEntityPathByParams(parentParams, uid, p.parentEntityID)}/${firestoreEntitySubPath(params, p.id)}`;
  } else {
    return firestoreEntityPathByParams(params, uid, p.id);
  }
}
