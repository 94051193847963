<div class="flex flex-row justify-center items-center">
  <dlc-input-image
    [config]="resolvedImageConfig()"
    (deleteImage)="transcribedNoteService.onDeleteImage()"
    (state)="hasImage.set($event.hasImage)"
    (uploadStart)="transcribedNoteService.onImageUploadStart($event)"
    (uploadComplete)="transcribedNoteService.onUploadComplete($event)"></dlc-input-image>
</div>

@if (hasImage()) {
  <div class="mt-4">
    <dlc-transcribe-btn [state]="transcribeBtnState()"></dlc-transcribe-btn>
  </div>
}

<div class="mt-4">
  <mat-form-field class="w-full">
    <mat-label>Note Title</mat-label>
    <input
      matInput
      [formControl]="titleControl" />

    <button
      mat-mini-fab
      matSuffix
      class="mr-2"
      (click)="clearTitleForm()">
      <mat-icon>close</mat-icon>
    </button>

    @if (titleControl.hasError("minlength") || titleControl.hasError("required")) {
      <mat-error>Title must be at least 3 characters long. </mat-error>
    }
  </mat-form-field>
</div>
<mat-tab-group
  mat-align-tabs="left"
  class="w-full">
  <mat-tab label="My Notes">
    <dlc-syncfusion-rte
      [value]="selectLatestNoteVersion()"
      (change)="store.addUserNoteVersion($event)"></dlc-syncfusion-rte>
  </mat-tab>

  @if (selectLatestTranscriptionVersion()) {
    <mat-tab label="Transcribed Image">
      <dlc-syncfusion-rte
        [placeholder]="'AI Image Transcription'"
        [value]="selectLatestTranscriptionVersion()"
        (change)="store.addTranscribedNoteVersion($event)"></dlc-syncfusion-rte>
    </mat-tab>
  }
</mat-tab-group>
