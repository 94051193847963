import {Injectable} from '@angular/core';
import {WriteBatch} from '@firebase/firestore';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {uuid} from '@ngpat/fn';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {httpsCallable, HttpsCallable, HttpsCallableResult} from 'firebase/functions';
import {BehaviorSubject, Subject} from 'rxjs';
import {take} from 'rxjs/operators';

import {GradeLevel, Question, Quiz} from '../+quizzes';
import {createQuestionsFromOpenAiResponse} from '../+quizzes/quiz.openai';
import {BaseEntity} from '../entity';
import {firestoreQuizQuestionsByEntity} from './database-paths';
import {GenerateQuizResponse} from './firestore.model';
import {getAorAn} from './open-ai-generate-quiz-questions.fns';

export interface OpenAiQuizGeneratorForm {
  description: string;
  gradeLevel: GradeLevel;
  message: string;
  name: string;
  subjects: string;
}

class OpenAiQuizGenerator {
  constructor(
    private generatorId: string,
    private callback: (generatorId: string) => void,
    public quiz: Quiz,
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  generateQuizQuestions(message: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const that = this;

    const openAiCompletionsFn: HttpsCallable<string, GenerateQuizResponse> = httpsCallable(
      this.customFirestoreService.functions,
      'openAIQuizQuestionAssistantGen2'
    );
    openAiCompletionsFn(message)
      .then((result: HttpsCallableResult<GenerateQuizResponse>) => {
        try {
          const response = result.data;
          const quizQuestions = JSON.parse(response.value);

          // console.log(quizQuestions);

          const questions: Question[] = createQuestionsFromOpenAiResponse(this.quiz, quizQuestions.quiz);

          that.customFirestoreService.user$.pipe(take(1)).subscribe({
            next: (user: User) => {
              const batch: WriteBatch = that.customFirestoreService.writeBatch();

              questions.forEach((question: Question) => {
                const docPath = `${firestoreQuizQuestionsByEntity(that.quiz as BaseEntity, user.uid)}/${question.id}`;

                batch.set(that.customFirestoreService.docRef(docPath), question);
              });

              batch.commit().then(() => {
                that.callback(that.generatorId);
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
}

@Injectable({
  providedIn: 'root'
})
export class OpenAiGenerateQuizService {
  quizGeneratorCache: {[key: string]: OpenAiQuizGenerator} = {};

  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  closeDialog$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  generateQuizQuestions(quiz: Quiz, message: string): void {
    this.showSpinner$.next(true);
    const generatorId = uuid();
    const quizGenerator = new OpenAiQuizGenerator(
      generatorId,
      this.removeQuizGenerator.bind(this),
      quiz,
      this.customFirestoreService,
      this.store
    );
    quizGenerator.generateQuizQuestions(message);
    this.quizGeneratorCache[generatorId] = quizGenerator;
  }

  removeQuizGenerator(generatorID: string): void {
    delete this.quizGeneratorCache[generatorID];
    this.showSpinner$.next(false);
    this.closeDialog$.next(true);
  }

  createMessage(formValues: OpenAiQuizGeneratorForm): string {
    let message = `Create `;
    if (formValues.gradeLevel) {
      message += `${getAorAn(formValues.gradeLevel.name)} ${formValues.gradeLevel.name} `;
    }

    message += `${formValues.name} `;

    if (formValues.subjects.length > 0) {
      message += `${formValues.subjects} `;
    }

    message += `quiz `;

    if (formValues.description.length > 0) {
      message += `about ${formValues.description} `;
    }

    // message += `with questions.`;

    return message;
  }
}
