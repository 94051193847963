<ul
  role="list"
  class="pl-0">
  @for (note of notes(); track note.id) {
    <li
      dlc-note-list-item
      class="mb-5 relative"
      [note]="note"
      (doCopyNote)="doCopyNote.emit($event)"
      (doEditNote)="doEditNote.emit($event)"></li>
  }
</ul>
