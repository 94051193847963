import {Injectable} from '@angular/core';
import {
  Exists,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {
  assignDeprecatedBaseEntitiesProperties,
  BaseEntity
} from '../entity/entity.model';
import {
  firestoreQueryPathByEntity,
  firestoreQuizCollection,
  firestoreJournalCollection,
  firestoreUserJournalCollection
} from '../firebase/database-paths';
import {QueryEngineCache} from '../services/query-engine-cache';
import {
  deleteJournals,
  updateJournals,
  upsertJournals
} from './journal.actions';
import {Journal} from './journal.model';
import {journalFeatureKey} from './journal.reducer';
import {selectAllJournals} from './journal.selectors';

@Injectable({
  providedIn: 'root'
})
export class JournalService implements NgPatFirebaseConnectionService {
  private _journalQuizQueryCache!: QueryEngineCache<Quiz>;
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Journal>;
  private _queryPrivateQuizService!: NgPatFirestoreCollectionQuery<Journal>;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );
  connectionKey = journalFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  deleteDoc$(classroom: BaseEntity | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserJournalCollection(uid)
        : firestoreJournalCollection();

      // const path = journal.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: BaseEntity | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserJournalCollection(uid)
        : firestoreJournalCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }

    return of(true);
  }

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<Journal>(
        {
          deleteManyAction: (ids: string[]) => deleteJournals({ids}),
          queryMember: true,
          updateManyAction: (journals: Journal[]) =>
            updateJournals({
              journals: aggregateUpdates(
                assignDeprecatedBaseEntitiesProperties(journals) as Journal[]
              )
            }),
          upsertManyAction: (journals: Journal[]) =>
            upsertJournals({
              journals: assignDeprecatedBaseEntitiesProperties(
                journals
              ) as Journal[]
            })
        },
        this.store,
        this.customFirestoreService
      );

    // TODO Not needed
    const queryJournalConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteJournals({ids}),
        queryMember: false,
        updateManyAction: (journals: Journal[]) =>
          updateJournals({
            journals: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(journals) as Journal[]
            )
          }),
        upsertManyAction: (journals: Journal[]) =>
          upsertJournals({
            journals: assignDeprecatedBaseEntitiesProperties(
              journals
            ) as Journal[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateQuizService =
      queryJournalConfig.createFirestoreCollectionQuery();

    const queryQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteQuizs({ids}),
        queryMember: false,
        updateManyAction: (quizs: Quiz[]) =>
          updateQuizs({
            quizs: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
            )
          }),
        upsertManyAction: (quizs: Quiz[]) =>
          upsertQuizs({
            quizs: assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (entity: Quiz, uid: string) =>
      `${firestoreQueryPathByEntity(entity as BaseEntity, uid)}/${firestoreQuizCollection()}`;

    this._journalQuizQueryCache = new QueryEngineCache<Quiz>(
      queryQuizConfig,
      this.store,
      selectAllJournals,
      quizPathGenerator,
      'id'
    );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreJournalCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateQuizService.onConnect(
      firestoreUserJournalCollection(<string>user.uid),
      null,
      null
    );
    this._journalQuizQueryCache.onConnect(user);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateQuizService.onDisconnect();
    this._journalQuizQueryCache.onDisconnect();

    // Unsubscribe to query before calling this
  }

  updateDoc(g: Journal) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByEntity(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(
    changes: Partial<Journal>,
    quiz: Journal,
    uid: string | null
  ): Observable<Journal> {
    if (uid) {
      return this.customFirestoreService
        .merge$<Journal>(firestoreQueryPathByEntity(quiz, uid), changes)
        .pipe(map((r: Exists<Journal>) => r.data));
    }

    return of(quiz);
  }
}
