import {
  IAPProductAndIRegisterProduct,
  InAppPurchaseWrapper,
  WebSubscriptionProducts
} from '@ngpat/store';

export const IOS_BASIC_MONTHLY = 'ec.quiz.basic.monthly';
export const IOS_BASIC_ANNUALLY = 'ec.quiz.basic.annually';

export const ANDROID_BASIC = 'ec.quiz.basic.monthly';
export const ANDROID_ANNUALLY = 'ec.quiz.basic.annually';

export const IN_APP_IOS_SUBSCRIPTION_ID = {
  BASIC: IOS_BASIC_MONTHLY
};

const AI_GENERATED_QUESTIONS_FEATURES = [
  'Create Quizzes',
  'Contribute to Quizzes',
  'Create Study Groups',
  'Contribute to Study Groups',
  'Create Classrooms',
  'Contribute to Classrooms',
  'Use AI to Generate Questions'
];

const EC_MONTHLY_SUBSCRIPTION: IAPProductAndIRegisterProduct = {
  canPurchase: false,
  // Common properties
  className: 'IAPProduct',
  description: 'AI Creates Questions',

  features: AI_GENERATED_QUESTIONS_FEATURES,
  group: 'Basic Plan',
  // will be overridden
  id: IOS_BASIC_MONTHLY,
  offers: [],
  owned: false,
  platform: InAppPurchaseWrapper.Platform.APPLE_APPSTORE,
  title: 'Basic Plan',
  type: InAppPurchaseWrapper.ProductType.PAID_SUBSCRIPTION
};

export const EVOLVING_COGNITION_PRODUCTS: IAPProductAndIRegisterProduct[] = [
  {
    ...EC_MONTHLY_SUBSCRIPTION,
    id: IOS_BASIC_MONTHLY,
    platform: InAppPurchaseWrapper.Platform.APPLE_APPSTORE,
    type: InAppPurchaseWrapper.ProductType.PAID_SUBSCRIPTION
  },
  {
    ...EC_MONTHLY_SUBSCRIPTION,
    id: IOS_BASIC_ANNUALLY,
    platform: InAppPurchaseWrapper.Platform.APPLE_APPSTORE,
    type: InAppPurchaseWrapper.ProductType.PAID_SUBSCRIPTION
  },
  {
    ...EC_MONTHLY_SUBSCRIPTION,
    id: ANDROID_BASIC,
    platform: InAppPurchaseWrapper.Platform.GOOGLE_PLAY,
    type: InAppPurchaseWrapper.ProductType.PAID_SUBSCRIPTION
  },
  {
    ...EC_MONTHLY_SUBSCRIPTION,
    id: ANDROID_ANNUALLY,
    platform: InAppPurchaseWrapper.Platform.GOOGLE_PLAY,
    type: InAppPurchaseWrapper.ProductType.PAID_SUBSCRIPTION
  }
];

export const WEB_SUBSCRIPTION_PRODUCTS: WebSubscriptionProducts = {
  // TEST
  prod_Pc7sgB2Gypr3MF: {
    features: AI_GENERATED_QUESTIONS_FEATURES
  },
  // PROD
  prod_Pc7xsKZQzXtM32: {
    features: AI_GENERATED_QUESTIONS_FEATURES
  }
};


/**
 * Return products by platform
 */
// export function getProductsByPlatform(): IRegisterProduct[] {
//   return EVOLVING_COGNITION_PRODUCTS.filter(p => p.platform === InAppPurchaseWrapper.currentPlatform);
// }
//
// /**
//  * Get product by ID
//  */
// export function getProductByIdAndPlatform(id: string): IRegisterProduct | undefined {
//   return EVOLVING_COGNITION_PRODUCTS.find(
//     p => p.id === id && p.platform === InAppPurchaseWrapper.currentPlatform
//   );
// }
