<div class="flex flex-row justify-center items-center flex-auto">
  <mat-icon
    [style.fontSize.px]="iconSize()"
    [style.width.px]="iconSize()"
    [style.height.px]="iconSize()"
    >{{ fontIcon() }}</mat-icon
  >
</div>
<div class="w-full flex">
  <button
    #actionButton
    class="flex flex-auto min-h-[28px]"
    dlc-rounded-text-icon-logsub-button
    color="primary"
    (doAction)="doAction.emit()">
    @if (ctaIcon().length > 0) {
      <mat-icon>{{ ctaIcon() }}</mat-icon>
    }
    {{ ctaText() }}
  </button>
</div>
