import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'dlc-ios-app-store-button,[dlc-ios-app-store-button],[dlcIosAppStoreButton]',
    imports: [CommonModule],
    templateUrl: './ios-app-store.component.html',
    styleUrl: './ios-app-store.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-ios-app-store-button'
    }
})
export class DlcIosAppStoreComponent {
  @Input() isBlack = true;
}
