import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, ViewEncapsulation} from '@angular/core';
import {coerceThemePalette} from '../button.fns';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[dlc-rounded-text-button], button[dlcRoundedTextButton]',
    imports: [CommonModule],
    templateUrl: './dlc-rounded-text-button.component.html',
    styleUrls: ['./dlc-rounded-text-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-rounded-text-button  dlc-rounded-button',
        '[attr.disabled]': `disabled() === true ? '' : null`,
        '[class.dlc-rounded-text-button-primary]': 'color() === "primary"',
        '[class.dlc-rounded-text-button-accent]': 'color() === "accent"',
        '[class.dlc-rounded-text-button-warn]': 'color() === "warn"',
        '[class.dlc-rounded-text-button-none]': 'color() === "none" || color() === undefined'
    }
})
export class DlcRoundedTextButtonComponent {
  disabled = input(false, {transform: coerceBooleanProperty});
  color = input('none', {
    transform: coerceThemePalette
  });
}
