import {Injectable} from '@angular/core';
import {generate8CharCodeLowercase} from '@ngpat/fn';
import {selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, forkJoin} from 'rxjs';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {
  updateFirestorePartialStudyGroup,
  updateStudyGroupEffect,
  upsertStudyGroups
} from './study-group.actions';
import {StudyGroup} from './study-group.model';
import {PartialStudyGroupState} from './study-group.reducer';
import {StudyGroupService} from './study-group.service';

@Injectable({
  providedIn: 'root'
})
export class StudyGroupEffects {
  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _studyGroupService
   */
  // deleteDoc$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(deleteStudyGroupFromfirestore),
  //     withLatestFrom(this.store.select(selectUserAccount)),
  //     switchMap(([action, account]) =>
  //       this.store.pipe(
  //         select(getClassroomByID(action.id)),
  //         switchMap((classRoom: Classroom | undefined) =>
  //           this._studyGroupService
  //             .deleteDoc$(classRoom, <string>account.uid)
  //             .pipe(map(() => deleteStudyGroup({id: action.id})))
  //         )
  //       )
  //     )
  //   )
  // );

  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _studyGroupService
   */
  // deleteDocs$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteStudyGroups),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getClassroomByID(action.ids[0])),
  //           switchMap((classRoom: Classroom | undefined) =>
  //             this._studyGroupService.deleteDocs$(classRoom, action.ids, <string>account.uid)
  //           )
  //         )
  //       )
  //     ),
  //   {dispatch: false}
  // );

  updateStudyGroup$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateStudyGroupEffect),
        switchMap(action => {
          return this._studyGroupService.updateDoc(action.studyGroup);
        })
      ),
    {dispatch: false}
  );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateFirestorePartialStudyGroup),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this._studyGroupService.updatePartialFirestore$(
            action.changes,
            action.studyGroup,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  upsertQuizs$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(upsertStudyGroups),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) => {
          interface StudyGroupUpdate {
            changes: Partial<StudyGroup>;
            studyGroup: StudyGroup;
          }

          const _studyGroupsUpdate: StudyGroupUpdate[] =
            action.studyGroups.reduce(
              (a: StudyGroupUpdate[], s: StudyGroup) => {
                const changes: Partial<StudyGroup> = {};

                let update = false;

                if (!s.mentorCode) {
                  changes.mentorCode = generate8CharCodeLowercase();
                  update = true;
                }

                if (!s.teacherCode) {
                  changes.teacherCode = generate8CharCodeLowercase();
                  update = true;
                }

                if (!s.studentCode) {
                  changes.studentCode = generate8CharCodeLowercase();
                  update = true;
                }

                if (update) {
                  a.push({
                    changes,
                    studyGroup: {...s}
                  });
                }

                return a;
              },
              <StudyGroupUpdate[]>[]
            );

          if (_studyGroupsUpdate.length) {
            return forkJoin(
              _studyGroupsUpdate.map((s: StudyGroupUpdate) =>
                this._studyGroupService.updatePartialFirestore$(
                  s.changes,
                  s.studyGroup,
                  account.uid
                )
              )
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  updateClassroomEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateStudyGroupEffect),
        switchMap(action => {
          return this._studyGroupService.updateDoc(action.studyGroup);
        })
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialStudyGroupState>,
    private _studyGroupService: StudyGroupService
  ) {}
}
