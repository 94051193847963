import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@ngpat/firebase';

import {
  firestoreEntityPathByParams,
  firestoreEntitySubPath
} from '../firebase/database-paths';
import {
  BaseEntity,
  CollaborativeEntity,
  CreateBaseSubEntityParams,
  CreateEntityParams
} from './entity.model';

@Injectable({
  providedIn: 'root'
})
export class BaseEntityService {
  constructor(private customFirestoreService: NgPatFirestoreService) {}

  createEntity$(
    entity: BaseEntity | CollaborativeEntity,
    params: CreateEntityParams,
    uid: string
  ) {
    const fireStorePath = firestoreEntityPathByParams(params, uid, entity.id);
    return this.customFirestoreService.upsertDoc$(fireStorePath, entity);
  }

  createSubEntity$(
    entity: BaseEntity | CollaborativeEntity,
    baseParams: CreateBaseSubEntityParams,
    entityParams: CreateBaseSubEntityParams,
    uid: string
  ) {
    const firestorePath = `${firestoreEntityPathByParams(
      baseParams,
      uid,
      baseParams.parentEntity.id
    )}/${firestoreEntitySubPath(entityParams, entity.id)}`;
    return this.customFirestoreService.upsertDoc$(firestorePath, entity);
  }
}
