import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

import {CollaborativeEntity} from '../entity/entity.model';
import {Classroom, CreateClassParams} from './classroom.model';

export const addClassroom = createAction('[Classroom/API] Add Classroom', props<{classroom: Classroom}>());

export const setClassroom = createAction('[Classroom/API] Set Classroom', props<{classroom: Classroom}>());

export const upsertClassroom = createAction('[Classroom/API] Upsert Classroom', props<{classroom: Classroom}>());

export const addClassrooms = createAction('[Classroom/API] Add Classrooms', props<{classrooms: Classroom[]}>());

export const upsertClassrooms = createAction('[Classroom/API] Upsert Classrooms', props<{classrooms: Classroom[]}>());

export const updateClassroom = createAction(
  '[Classroom/API] Update Classroom',
  props<{classroom: Update<Classroom>}>()
);

export const updateClassroomEffect = createAction(
  '[StudyGroup/API] Update Classroom Effect',
  props<{classroom: CollaborativeEntity}>()
);

export const updateClassrooms = createAction(
  '[Classroom/API] Update Classrooms',
  props<{classrooms: Update<Classroom>[]}>()
);

export const mapClassroom = createAction(
  '[Classroom/API] Map Classroom',
  props<{entityMap: EntityMapOne<Classroom>}>()
);

export const mapClassrooms = createAction('[Classroom/API] Map Classrooms', props<{entityMap: EntityMap<Classroom>}>());

export const deleteClassroom = createAction('[Classroom/API] Delete Classroom', props<{id: string}>());

export const deleteClassrooms = createAction('[Classroom/API] Delete Classrooms', props<{ids: string[]}>());

export const loadClassrooms = createAction('[Classroom/API] Load Classrooms', props<{classrooms: Classroom[]}>());

export const setClassrooms = createAction('[Classroom/API] Set Classrooms', props<{classrooms: Classroom[]}>());

export const clearClassrooms = createAction('[Classroom/API] Clear Classrooms');

export const selectClassroomID = createAction('[Classroom/API] Select Classroom', props<{id: string}>());

export const createClassroomEffect = createAction(
  '[Classroom/API] Create Classroom',
  props<{params: CreateClassParams}>()
);

export const updateFirestorePartialClassroom = createAction(
  '[Classroom/API] Save Partial Classroom',
  props<{changes: Partial<Classroom>; classroom: Classroom}>()
);
