import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, ViewEncapsulation} from '@angular/core';
import {coerceThemePalette} from '../button.fns';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[dlc-rounded-text-icon-button], button[dlcRoundedTextIconButton]',
    imports: [CommonModule],
    templateUrl: './dlc-rounded-text-icon-button.component.html',
    styleUrls: ['./dlc-rounded-text-icon-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-rounded-text-icon-button dlc-rounded-button',
        '[attr.disabled]': `disabled() === true ? '' : null`,
        '[class.dlc-rounded-text-icon-button-primary]': 'color() === "primary"',
        '[class.dlc-rounded-text-icon-button-accent]': 'color() === "accent"',
        '[class.dlc-rounded-text-icon-button-warn]': 'color() === "warn"',
        '[class.dlc-rounded-text-icon-button-none]': 'color() === "none" || color() === undefined',
        '[class.dlc-rounded-text-icon-button-vertical]': 'vertical()'
    }
})
export class DlcRoundedTextIconButtonComponent {
  disabled = input(null, {transform: coerceBooleanProperty});
  color = input('none', {transform: coerceThemePalette});
  vertical = input(null, {transform: coerceBooleanProperty});
}
