import {NavItemDict} from '../../../+ui/ui.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictRoot: NavItemDict = {
  [NAV.MOBILE_ACCOUNT_PAGE]: {
    icon: 'account_circle',
    id: NAV.MOBILE_ACCOUNT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Account',
    navigateTo: ['mobile', 'account'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/account'
  },
  [NAV.MOBILE_ADD_NOTE_PAGE]: {
    icon: 'edit_note',
    id: NAV.MOBILE_ADD_NOTE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Add Note',
    navigateTo: ['mobile', 'add-personal-note'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/add-personal-note'
  },
  [NAV.MOBILE_ADMIN_SUBJECTS_PAGE]: {
    icon: 'subscriptions',
    id: NAV.MOBILE_ADMIN_SUBJECTS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Admin Subjects',
    navigateTo: ['mobile', 'admin-subjects'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/admin-subjects'
  },
  [NAV.MOBILE_ADMIN_USERS_PAGE]: {
    icon: 'subscriptions',
    id: NAV.MOBILE_ADMIN_USERS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Admin Users',
    navigateTo: ['mobile', 'admin-users'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/admin-users'
  },
  [NAV.MOBILE_HOME_PAGE]: {
    icon: 'home',
    id: NAV.MOBILE_HOME_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Home',
    navigateTo: ['home'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/home'
  },
  [NAV.MOBILE_LOGIN_PAGE]: {
    icon: 'login',
    id: NAV.MOBILE_LOGIN_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Login',
    navigateTo: ['mobile', 'login'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/login'
  },
  [NAV.MOBILE_NOTES_PAGE]: {
    icon: 'notes',
    id: NAV.MOBILE_NOTES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Notes',
    navigateTo: ['mobile', 'personal-notes'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/personal-notes'
  },

  [NAV.MOBILE_PRIVACY_POLICY_PAGE]: {
    icon: 'security',
    id: NAV.MOBILE_PRIVACY_POLICY_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Privacy Policy',
    navigateTo: ['privacy-policy'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/privacy-policy'
  },

  [NAV.MOBILE_PROFILE_PAGE]: {
    icon: 'account_circle',
    id: NAV.MOBILE_PROFILE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Profile',
    navigateTo: ['mobile', 'profile'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/profile'
  },

  [NAV.MOBILE_SUBSCRIBE_PAGE]: {
    icon: 'subscriptions',
    id: NAV.MOBILE_SUBSCRIBE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Subscribe',
    navigateTo: ['mobile', 'subscribe'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/subscribe'
  },
  [NAV.MOBILE_SUPPORT_PAGE]: {
    icon: 'support',
    id: NAV.MOBILE_SUPPORT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Support',
    navigateTo: ['mobile', 'support'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/support'
  },
  [NAV.MOBILE_TERMS_OF_USE_PAGE]: {
    icon: 'policy',
    id: NAV.MOBILE_TERMS_OF_USE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Terms Of Use',
    navigateTo: ['terms-of-use'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/terms-of-use'
  }
};
