import {NgPatMonitorAccounts} from '@ngpat/store';

import {Member, MemberListItem} from '../+members/members.model';
import {UiState, UiStorage} from './ui.model';

// See libs/evolving-cognition/domain/src/lib/+ui/ui.model.ts:129
export function createUiPayloadForIndexedDBStorage(state: UiState): UiStorage {
  const keys = [
    'previousNavItemID',
    'currentNavItemID',
    'currentQuizID',
    'currentQuestionID',
    'currentClassID',
    'currentStudyGroupID',
    'currentQuizReviewID',
    'isDesktop',
    'isMobile',
    'isIOS',
    'currentURL',
    'mobileHomeLayoutState'
  ];

  return keys.reduce(
    (a: any, key: string) => {
      (<any>a)[key] = (<any>state)[key];
      return a;
    },
    {
      // For Dexie database
      id: 1
    }
  );
}

/**
 *
 * @param state
 * @param loggedInUserIsMentor
 * @param mentorAccounts
 * @param members
 * @param entityID = classroom id, study group id, etc
 */
export function getCurrentClassMembersWithoutMentors(
  state: UiState,
  loggedInUserIsMentor: boolean,
  mentorAccounts: NgPatMonitorAccounts,
  members: Member[],
  entityID: string
): MemberListItem[] {
  return (
    members

      // Get Members Of BaseEntity
      .filter((m: Member) => m.entityIDs.includes(entityID))

      // Logged In User Is Mentor, filter non mentored membersList
      .filter((m: Member) => {
        if (loggedInUserIsMentor) {
          return mentorAccounts[m.uid];
        }

        return true;
      })

      .map(
        (m: Member) =>
          <MemberListItem>{
            entityID,
            member: m,
            role: m.entities[entityID].role,
            uid: m.uid,
            username: m.username
          }
      )
  );
}
