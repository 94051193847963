import {NavItemDict} from '../../../+ui/ui.model';
import {URL_PROPS} from '../../../entity/entity.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictStudyGroup: NavItemDict = {
  [NAV.MOBILE_STUDY_GROUP_ALL_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_STUDY_GROUP_ALL_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'All Grades',
    navigateTo: ['mobile', 'study-group-all-grades'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    url: '/mobile/study-group-all-grades'
  },
  [NAV.MOBILE_STUDY_GROUP_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_STUDY_GROUP_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Study Group',
    navigateTo: ['mobile', 'study-group-create'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    url: '/mobile/study-group-create'
  },
  [NAV.MOBILE_STUDY_GROUP_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_STUDY_GROUP_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit',
    navigateTo: ['mobile', 'study-group-edit'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    url: '/mobile/study-group-edit'
  },
  [NAV.MOBILE_STUDY_GROUP_ITEM_PAGE]: {
    icon: 'groups',
    id: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Study Group',
    navigateTo: ['mobile', 'study-group-item'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    url: '/mobile/study-group-item'
  },
  [NAV.MOBILE_STUDY_GROUP_LIST_PAGE]: {
    icon: 'groups',
    id: NAV.MOBILE_STUDY_GROUP_LIST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Study Groups',
    navigateTo: ['mobile', 'study-group-list'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/study-group-list'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Add Quiz',
    navigateTo: ['mobile', 'study-group-quiz-create'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    url: '/mobile/study-group-quiz-create'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Quiz',
    navigateTo: ['mobile', 'study-group-quiz-edit'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    url: '/mobile/study-group-quiz-edit'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Grades',
    navigateTo: ['mobile', 'study-group-quiz-grades'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    url: '/mobile/study-group-quiz-grades'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_CREATE_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Create Question',
    navigateTo: ['mobile', 'study-group-quiz-question-create'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE,
    url: '/mobile/study-group-quiz-question-create'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Question',
    navigateTo: ['mobile', 'study-group-quiz-question-edit'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE,
    url: '/mobile/study-group-quiz-question-edit'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_REVIEW_PAGE]: {
    icon: 'read_more',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_REVIEW_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Review',
    navigateTo: ['mobile', 'study-group-quiz-review'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID, URL_PROPS.QUIZ_REVIEW_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE,
    url: '/mobile/study-group-quiz-review'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'study-group-quiz-stats'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.STUDY_GROUP_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_ITEM_PAGE,
    url: '/mobile/study-group-quiz-stats'
  },
  [NAV.MOBILE_STUDY_GROUP_QUIZ_TEST_PAGE]: {
    icon: 'quiz',
    id: NAV.MOBILE_STUDY_GROUP_QUIZ_TEST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Quiz',
    navigateTo: ['mobile', 'study-group-quiz-item-test'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.STUDY_GROUP_ID],
    previousNavItemID: NAV.MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE,
    url: '/mobile/study-group-quiz-item-test'
  }
};
