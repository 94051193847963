import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as CourseReducer from './course.reducer';

export const selectCourseState =
  createFeatureSelector<CourseReducer.CourseState>(
    CourseReducer.courseFeatureKey
  );

const {selectAll, selectEntities, selectIds, selectTotal} =
  CourseReducer.courseAdapter.getSelectors();

export const selectAllCourses = createSelector(selectCourseState, selectAll);
export const selectCourseEntities = createSelector(
  selectCourseState,
  selectEntities
);
export const selectCourseIds = createSelector(selectCourseState, selectIds);
export const selectCourseTotal = createSelector(selectCourseState, selectTotal);
export const selectedCourseID = createSelector(
  selectCourseState,
  (state: CourseReducer.CourseState) => state.selectedCourseID
);
