<h2 mat-dialog-title>Delete Note</h2>
<mat-dialog-content>
  <div class="mb-8">Are you sure you want to delete {{ data.title }} ?</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="false">
    No
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true">
    Yes
  </button>
</mat-dialog-actions>
