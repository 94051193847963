import {Inject, Injectable} from '@angular/core';
import {AlgoliaChipsAutocomplete} from '@gigasoftware/shared/domain';

import {AlgoliaPublishedQuiz} from '../../+quizzes';
import {
  EC_ENVIRONMENT,
  EvolutionCognitionEnvironment
} from '../../models/environmnent';

@Injectable()
export class AlgoliaSearchSubjects extends AlgoliaChipsAutocomplete<AlgoliaPublishedQuiz> {
  constructor(
    @Inject(EC_ENVIRONMENT) private environment: EvolutionCognitionEnvironment
  ) {
    super(
      {
        apiKey: environment.algoliaSubjects.apiKey,
        appId: environment.algoliaSubjects.appId,
        index: environment.algoliaSubjects.index
      },
      'name'
    );
  }
}
