import {InjectionToken} from '@angular/core';

import {TimeStamp} from '../models/time-stamp.model';
import {UserPermissions} from '../models/user.model.deprecated';

export interface BaseEntity {
  createdAt: TimeStamp | null; // firebaseConfig.firestore.FieldValue;
  description: string | null;
  entityType: number;
  id: string;
  imagePath: string | null | undefined;
  isCollaborative: boolean;
  isDefault: boolean;
  isPrivate: boolean;
  name: string;
  parentEntityID: string | null;
  parentEntityType: number | null;
  // @deprecated use parentEntityID
  parentProjectID?: string | null;
  // @deprecated use parentEntityType
  parentProjectType?: number | null;
  // @deprecated use entityType
  projectType?: number;
  updatedAt: TimeStamp | null; // firebaseConfig.firestore.FieldValue;
}

/**
 * For deprecated properties of BaseEntity, if a deprecated property is used, the new property should be used instead.
 * This function reassigns the deprecated properties to the new properties only if the new property is not already assigned.
 */
export function assignDeprecatedBaseEntityProperties(
  p: BaseEntity
): BaseEntity {
  if (p.parentProjectID && !(p.parentProjectID && p.parentProjectID.length)) {
    p.parentEntityID = p.parentProjectID;
    delete p.parentProjectID;
  }

  if (p.parentProjectType && p.parentProjectType !== null) {
    p.parentEntityType = p.parentProjectType;
    delete p.parentProjectType;
  }

  if (p.projectType && p.projectType !== null) {
    p.entityType = p.projectType;
    delete p.projectType;
  }

  return p;
}

/**
 * Update an array of BaseEntity objects with deprecated properties.
 */
export function assignDeprecatedBaseEntitiesProperties(
  p: BaseEntity[]
): BaseEntity[] {
  return p.map((i: BaseEntity) => assignDeprecatedBaseEntityProperties(i));
}

export interface CollaborativeEntity extends BaseEntity, UserPermissions {
  /**
   * given to parent, guardian, mentor, etc.
   */
  mentorCode: string;

  // joinCode: string;
  studentCode: string;
  teacherCode: string;
}

export type CollaborationEntityForm = Extract<
  BaseEntity,
  'name' | 'description' | 'isPrivate' | 'isCollaborative'
>;

/**
 * If changed, verify updated at
 * libs/web-platform/data-access/src/lib/entity/entity.fns.ts:18
 */
export interface BaseEntityType {
  id: number;
  name: string;
}

export interface DefaultEntityTypeDict {
  CLASS: BaseEntityType;
  COURSE: BaseEntityType;
  INVESTIGATION: BaseEntityType;
  JOURNAL: BaseEntityType;
  PRIVATE: BaseEntityType;
  PROJECT: BaseEntityType;
  QUIZ: BaseEntityType;
  RESEARCH: BaseEntityType;
  STUDY_GROUP: BaseEntityType;
}

export const DEFAULT_PLATFORM_ENTITY_TYPE_DICT: DefaultEntityTypeDict = {
  CLASS: {
    id: 4,
    name: 'Class'
  },
  COURSE: {
    id: 2,
    name: 'Course'
  },
  INVESTIGATION: {
    id: 6,
    name: 'Investigation'
  },
  JOURNAL: {
    id: 8,
    name: 'Journal'
  },
  PRIVATE: {
    id: 0,
    name: 'Private'
  },
  PROJECT: {
    id: 7,
    name: 'Project'
  },
  QUIZ: {
    id: 1,
    name: 'Quiz'
  },
  RESEARCH: {
    id: 5,
    name: 'Research'
  },
  STUDY_GROUP: {
    id: 3,
    name: 'Study Group'
  }
};

export const PLATFORM_ENTITY_TYPES: BaseEntityType[] = Object.values(
  DEFAULT_PLATFORM_ENTITY_TYPE_DICT
);

export const ENTITY_TYPE_BY_ID = new InjectionToken('ENTITY_TYPE_BY_ID');

export const ENTITY_TYPE_BY_ID_DICT = PLATFORM_ENTITY_TYPES.reduce(
  (a: {[key: string]: BaseEntityType}, i: BaseEntityType) => {
    a[i.id.toString(10)] = i;

    return a;
  },
  {}
);

export interface CreateEntityParams {
  assigned?: boolean; // only used for quiz
  description: string;
  entityTypeValue: number;
  id: string;
  isCollaborative: boolean;
  isPrivate: boolean;
  name: string;
  // @deprecated use entityTypeValue
  projectTypeValue?: number;
}

export interface CreateBaseSubEntityParams extends CreateEntityParams {
  parentEntity: BaseEntity;
}

export interface UrlProps {
  CLASSROOM_ID: string; // ClassroomID
  INVESTIGATIONS_ID: string;
  JOURNAL_ID: string;
  PROJECT_ID: string;
  QUESTION_ID: string;
  QUIZ_ID: string;
  QUIZ_REVIEW_ID: string;
  RESEARCH_ID: string;
  STU: string; // STUDENT
  STUDY_GROUP_ID: string;
  // ANSWER_ID: string;
}

export const URL_PROPS: UrlProps = {
  CLASSROOM_ID: 'currentClassID',
  INVESTIGATIONS_ID: 'currentInvestigationsID',
  JOURNAL_ID: 'currentJournalID',
  PROJECT_ID: 'currentProjectID',
  QUESTION_ID: 'currentQuestionID',
  QUIZ_ID: 'currentQuizID',

  QUIZ_REVIEW_ID: 'currentQuizReviewID',
  RESEARCH_ID: 'currentResearchID',
  STU: 'stu',
  STUDY_GROUP_ID: 'currentStudyGroupID'
  // ANSWER_ID: 'currentAnswerID'
};

export type JoinCodeType = 'mentor' | 'student' | 'teacher';

export enum JoinCodeTypes {
  MENTOR = 'mentor',
  STUDENT = 'student',
  TEACHER = 'teacher'
}
