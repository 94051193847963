import {NavItemDict} from '../../../+ui/ui.model';
import {URL_PROPS} from '../../../entity/entity.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictClass: NavItemDict = {
  [NAV.MOBILE_CLASS_ALL_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_CLASS_ALL_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'All Grades',
    navigateTo: ['mobile', 'class-all-grades'],
    paramKeys: [URL_PROPS.STUDY_GROUP_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_CLASS_ITEM_PAGE,
    url: '/mobile/class-all-grades'
  },
  [NAV.MOBILE_CLASS_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_CLASS_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Class',
    navigateTo: ['mobile', 'class-create'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_CLASS_LIST_PAGE,
    url: '/mobile/class-create'
  },
  [NAV.MOBILE_CLASS_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_CLASS_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit',
    navigateTo: ['mobile', 'class-edit'],
    paramKeys: [URL_PROPS.CLASSROOM_ID],
    previousNavItemID: NAV.MOBILE_CLASS_LIST_PAGE,
    url: '/mobile/class-edit'
  },
  [NAV.MOBILE_CLASS_ITEM_PAGE]: {
    icon: 'school',
    id: NAV.MOBILE_CLASS_ITEM_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Class',
    navigateTo: ['mobile', 'class-item'],
    paramKeys: [URL_PROPS.CLASSROOM_ID],
    previousNavItemID: NAV.MOBILE_CLASS_LIST_PAGE,
    url: '/mobile/class-item'
  },
  [NAV.MOBILE_CLASS_LIST_PAGE]: {
    icon: 'school',
    id: NAV.MOBILE_CLASS_LIST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Classes',
    navigateTo: ['mobile', 'class-list'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/mobile/class-list'
  },
  // [NAV.MOBILE_CLASS_QUIZ_ALL_STUDENTS_GRADES_PAGE]: {
  //   id: NAV.MOBILE_CLASS_QUIZ_ALL_STUDENTS_GRADES_PAGE,
  //   url: '/mobile/class-all-grades',
  //   navigateTo: ['mobile', 'class-quiz-all-student-grades'],
  //   previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
  //   icon: 'grading',
  //   name: 'All Grades',
  //   paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
  //   includeTopNavForParent: true,
  //   includeLogoForParent: false
  // },
  [NAV.MOBILE_CLASS_QUIZ_ALL_STUDENT_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_CLASS_QUIZ_ALL_STUDENT_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'All Grades',
    navigateTo: [],
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    url: '/mobile/class-quiz-all-student-grades'
  },
  [NAV.MOBILE_CLASS_QUIZ_CREATE_PAGE]: {
    icon: 'add',
    id: NAV.MOBILE_CLASS_QUIZ_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Add Quiz',
    navigateTo: ['mobile', 'class-quiz-create'],
    paramKeys: [URL_PROPS.CLASSROOM_ID],
    previousNavItemID: NAV.MOBILE_CLASS_ITEM_PAGE,
    url: '/mobile/class-quiz-create'
  },
  [NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Quiz',
    navigateTo: ['mobile', 'class-quiz-edit'],
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    url: '/mobile/class-quiz-edit'
  },
  [NAV.MOBILE_CLASS_QUIZ_QUESTION_CREATE_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_CLASS_QUIZ_QUESTION_CREATE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Create Question',
    navigateTo: ['mobile', 'class-quiz-question-create'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE,
    url: '/mobile/class-quiz-question-create'
  },
  [NAV.MOBILE_CLASS_QUIZ_QUESTION_EDIT_PAGE]: {
    icon: 'edit',
    id: NAV.MOBILE_CLASS_QUIZ_QUESTION_EDIT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Edit Question',
    navigateTo: ['mobile', 'class-quiz-question-edit'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.QUESTION_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_EDIT_PAGE,
    url: '/mobile/class-quiz-question-edit'
  },
  [NAV.MOBILE_CLASS_QUIZ_REVIEW_PAGE]: {
    icon: 'read_more',
    id: NAV.MOBILE_CLASS_QUIZ_REVIEW_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Review',
    navigateTo: ['mobile', 'class-quiz-review'],
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID, URL_PROPS.QUIZ_REVIEW_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE,
    url: '/mobile/class-quiz-review'
  },
  [NAV.MOBILE_CLASS_QUIZ_STATS_PAGE]: {
    icon: 'school',
    id: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Class',
    navigateTo: ['mobile', 'class-quiz-stats'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.CLASSROOM_ID],
    previousNavItemID: NAV.MOBILE_CLASS_ITEM_PAGE,
    url: '/mobile/class-quiz-stats'
  },
  [NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE]: {
    icon: 'grading',
    id: NAV.MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Grades',
    navigateTo: ['mobile', 'class-quiz-student-grades'],
    paramKeys: [URL_PROPS.CLASSROOM_ID, URL_PROPS.QUIZ_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    url: '/mobile/class-quiz-student-grades'
  },
  [NAV.MOBILE_CLASS_QUIZ_TEST_PAGE]: {
    icon: 'school',
    id: NAV.MOBILE_CLASS_QUIZ_TEST_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Class',
    navigateTo: ['mobile', 'class-quiz-test'],
    paramKeys: [URL_PROPS.QUIZ_ID, URL_PROPS.CLASSROOM_ID],
    previousNavItemID: NAV.MOBILE_CLASS_QUIZ_STATS_PAGE,
    url: '/mobile/class-quiz-test'
  }
};
