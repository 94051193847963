import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DlcRoundedTextIconButtonComponent} from '../../../button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {DlcTranscribeBtnState} from '../services/note.model';

@Component({
    selector: 'dlc-transcribe-btn',
    imports: [CommonModule, DlcRoundedTextIconButtonComponent, MatIconModule, MatProgressSpinnerModule],
    templateUrl: './dlc-transcribe-btn.component.html',
    styleUrl: './dlc-transcribe-btn.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-transcribe-btn'
    }
})
export class DlcTranscribeBtnComponent {
  btnState = signal(DlcTranscribeBtnState.DISABLED);

  isDisabled = computed(() => {
    const state = this.btnState();
    return (
      state === DlcTranscribeBtnState.DISABLED ||
      state === DlcTranscribeBtnState.IN_PROGRESS ||
      state === DlcTranscribeBtnState.PENDING
    );
  });

  transcribeInProgress = computed(() => {
    return this.btnState() === DlcTranscribeBtnState.IN_PROGRESS;
  });

  transcribePending = computed(() => {
    return this.btnState() === DlcTranscribeBtnState.PENDING;
  });

  doTranscribe: OutputEmitterRef<boolean> = output();

  @Input()
  set state(state: DlcTranscribeBtnState | undefined) {
    if (state !== undefined) {
      this.btnState.set(state);
    }
  }

  onTranscribe() {
    this.doTranscribe.emit(true);
    this.btnState.set(DlcTranscribeBtnState.PENDING);
  }
}
