import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  OutputEmitterRef,
  ViewEncapsulation
} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';

import {convertRemoteConfigToCodeName} from './dlc-input-code.fns';
import {CodeName} from './dlc-input-code.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-input-code'
  },
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule
  ],
  selector: 'dlc-input-code',
  styleUrl: './dlc-input-code.component.scss',
  templateUrl: './dlc-input-code.component.html'
})
export class DlcInputCodeComponent {
  codeForm = new FormControl('', Validators.required);

  dataSource = input(<CodeName[]>[], {
    transform: convertRemoteConfigToCodeName
  });

  displayedColumns: string[] = ['name', 'action'];
  doAdd: OutputEmitterRef<string> = output();

  doRemove: OutputEmitterRef<string> = output();

  hasData = computed(() => {
    return this.dataSource().length > 0;
  });

  onAdd(): void {
    if (this.codeForm.value) {
      this.doAdd.emit(this.codeForm.value);
      this.codeForm.reset();
    }
  }
}
