import {Injectable} from '@angular/core';
import {
  NgPatFirestoreCollectionQuery,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatMonitorAccounts,
  NgPatServiceConnector,
  selectNgPatLoggedInUID,
  selectNgPatMentorAccountsDict
} from '@ngpat/store';
import {ComponentStore} from '@ngrx/component-store';
import {createEntityAdapter, EntityState, Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {where} from 'firebase/firestore';
import {Observable, ReplaySubject, Subject} from 'rxjs';

import {StudentGrade} from '../../+classrooms/class-grades/class-grades.model';
import {Member} from '../../+members/members.model';
import {selectAllMemberss} from '../../+members/members.selectors';
import {Quiz, TakeQuizResult} from '../../+quizzes/quiz.model';
import {CollaborativeEntity} from '../../entity/entity.model';
import {firestoreQuizGradesByEntity} from '../../firebase/database-paths';
import {selectHasActiveSubscription} from '../../subscription/subscription.selectors';
import {Journal} from '../journal.model';
import {highestQuizGradeByStudent} from './journal-grades.fns';

export interface JournalQuizGradesState extends EntityState<TakeQuizResult> {
  journal: Journal | null;
  quiz: Quiz | null;
}

@Injectable()
export class JournalQuizGradesService
  extends ComponentStore<JournalQuizGradesState>
  implements NgPatFirebaseConnectionService
{
  private _adapter = createEntityAdapter<TakeQuizResult>();
  private _isConnected$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _onDestroy$: Subject<boolean> = new Subject();
  private _path$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _queryCollaborationService: NgPatFirestoreCollectionQuery<TakeQuizResult>;
  private _user$: ReplaySubject<NgPatAccountState> =
    new ReplaySubject<NgPatAccountState>(1);
  readonly addClassAndQuiz = this.updater(
    (state, {journal, quiz}: {journal: Journal; quiz: Quiz}) => {
      return {
        ...state,
        journal,
        quiz
      };
    }
  );
  readonly allSelect$: Observable<TakeQuizResult[]> = this.select(
    (state: JournalQuizGradesState) => {
      const {selectAll} = this._adapter.getSelectors();
      return selectAll(state);
    }
  );
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );
  connectionKey = null;
  readonly deleteMany = this.updater((state, ids: string[]) =>
    this._adapter.removeMany(ids, state)
  );
  journal!: Journal;
  quiz!: Quiz;
  readonly selectJournal$: Observable<Journal | null> = this.select(
    (state: JournalQuizGradesState) => state.journal
  );
  readonly selectQuiz$: Observable<Quiz | null> = this.select(
    (state: JournalQuizGradesState) => state.quiz
  );
  readonly students$: Observable<StudentGrade[]> = this.select(
    this.store.pipe(select(selectAllMemberss)),
    this.store.pipe(select(selectHasActiveSubscription)),
    this.store.pipe(select(selectNgPatMentorAccountsDict)),
    this.store.pipe(select(selectNgPatLoggedInUID)),
    this.allSelect$,
    this.selectQuiz$,
    this.selectJournal$,
    (
      members: Member[],
      isMentor: boolean,
      mentorAccts: NgPatMonitorAccounts,
      loggedInUID: string | null,
      quizResults: TakeQuizResult[],
      quiz: Quiz | null,
      journal: Journal | null
    ) => {
      let _members: Member[] = [];

      if (isMentor) {
        _members = members.filter(
          (m: Member) => mentorAccts[m.uid] || m.uid === loggedInUID
        );
      }

      return _members
        .filter(
          (m: Member) => m.entities[this.journal.id] // has this class
          // (m.journals[this.journal.id].role === ROLES.Student || // is a student of this class
          //   m.journals[this.journal.id].role === ROLES.Mentor) // is a mentor of this class
        )
        .map((student: Member) => {
          const attempts = quizResults.filter(
            (r: TakeQuizResult) => r.createdByUID === student.uid
          ).length;

          const grade: StudentGrade = {
            attempts,
            quizID: quiz?.id,
            quizName: quiz?.name,
            quizTaken: attempts > 0,
            result: highestQuizGradeByStudent(student, quizResults),
            student,
            uid: student.uid
          };

          return {
            ...grade,
            quizTaken: grade.quizTaken !== null
          };
        });
    }
  );
  readonly updateMany = this.updater(
    (state, questions: Update<TakeQuizResult>[]) => {
      return this._adapter.updateMany(questions, state);
    }
  );
  readonly upsertMany = this.updater((state, questions: TakeQuizResult[]) => {
    return this._adapter.upsertMany(questions, state);
  });

  constructor(
    private store: Store,
    private customFirestoreService: NgPatFirestoreService
  ) {
    super(
      createEntityAdapter<TakeQuizResult>().getInitialState({
        journal: null,
        quiz: null
      })
    );

    const that = this;
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<TakeQuizResult>(
        {
          deleteManyUpdater: (ids: string[]) => that.deleteMany(ids),
          queryMember: false,
          updateManyUpdater: (questions: TakeQuizResult[]) =>
            that.updateMany(aggregateUpdates(questions)),
          upsertManyUpdater: (questions: TakeQuizResult[]) =>
            that.upsertMany(questions)
          // logUpsert: true
        },
        store,
        customFirestoreService
      );
  }

  private getKey(q: CollaborativeEntity) {
    return `${q.id}-${q.parentEntityID}-grade`;
  }

  init(c: Journal, q: Quiz) {
    const that = this;
    this.journal = c;
    this.quiz = q;

    this.addClassAndQuiz({journal: c, quiz: q});

    this.connection.setConnectionKey(this.getKey(c));
  }

  onConnect(user: NgPatAccountState) {
    if (this.journal && user.uid) {
      this._isConnected$.next(true);
      const _path = firestoreQuizGradesByEntity(this.journal, user.uid);
      this._path$.next(_path);
      this._queryCollaborationService.onConnect(_path, null, user.uid, [
        where('quizID', '==', this.quiz.id)
      ]);
      this._user$.next(user);
    }
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this.connection.destroy();
  }

  onDisconnect(user: NgPatAccountState) {
    if (this.journal && user.uid) {
      this._isConnected$.next(false);
    }

    this.connection.deleteKey();
    this._queryCollaborationService.onDisconnect(user.uid);
  }
}
