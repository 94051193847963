import {CommonModule} from '@angular/common';
import {Component, EventEmitter, Output, Signal, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {DlcRoundedIconButtonComponent} from '../components/button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {Store} from '@ngrx/store';
import {DEFAULT_NOTIFICATION, NOTIFICATION_ID, UiNotification} from './notification.model';
import {selectAllNotifications} from '@ngpat/store';

@Component({
    selector: 'dlc-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-notifications'
    },
    imports: [
        CommonModule,
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        DlcRoundedIconButtonComponent
    ]
})
export class NotificationsComponent {
  defaultNotifications: NOTIFICATION_ID = DEFAULT_NOTIFICATION;

  @Output() notificationAction: EventEmitter<UiNotification> = new EventEmitter<UiNotification>();

  notifications: Signal<UiNotification[]> = <Signal<UiNotification[]>>(
    this.store.selectSignal(selectAllNotifications)
  );

  constructor(private store: Store) {}
}
