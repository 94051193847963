import {Injectable} from '@angular/core';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {select, Store} from '@ngrx/store';

import {concatMemberUIDEntityID} from '../common.selectors';
import {MemberQuery} from './member.query';
import {Member} from './members.model';
import {membersFeatureKey} from './members.reducer';

@Injectable({
  providedIn: 'root'
})
export class MembersService implements NgPatFirebaseConnectionService {
  connectionKey = membersFeatureKey;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );
  // private _queryService: FirestoreCollectionQuery<Member>;
  private _cache: {[uid: string]: MemberQuery} = {};

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  addUIDs(m: Member[]) {
    if (m && m.length) {
      for (let i = 0; i < m.length; i++) {
        if (!this._cache[m[i].uid]) {
          this._cache[m[i].uid] = new MemberQuery(
            m[i],
            this.customFirestoreService,
            this.store
          );
        } else {
          this._cache[m[i].uid].addEntity(m[i]);
        }
      }
    }
  }

  ngPatOnInit() {
    this.store
      .pipe(select(concatMemberUIDEntityID))
      .subscribe((r: Member[]) => {
        this.addUIDs(r);
      });
  }

  onConnect(user: NgPatAccountState) {
    for (const key in this._cache) {
      this._cache[key].onConnect();
    }
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    // this._queryService.onDisconnect();

    for (const key in this._cache) {
      this._cache[key].onDisconnect();
    }
  }
}
