import {Quiz} from '../../+quizzes';
import {BASE_COLLECTION_NAME} from './model';

export function firestoreUserQuizCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.QUIZZES}`;
}
export function firestoreUserQuizDoc(uid: string, quizID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.QUIZZES}/${quizID}`;
}
export function firestoreUserCourseCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.COURSES}`;
}

export function firestorePublishedQuizzesCollection() {
  return `${BASE_COLLECTION_NAME.PUBLISHED_QUIZZES}`;
}

export function firestorePublishedQuizDoc(quizID: string): string {
  return `${BASE_COLLECTION_NAME.PUBLISHED_QUIZZES}/${quizID}`;
}

export function firestorePublishedQuizQuestionDoc(quizID: string, questionID: string): string {
  return `${BASE_COLLECTION_NAME.PUBLISHED_QUIZZES}/${quizID}/${BASE_COLLECTION_NAME.QUESTIONS}/${questionID}`;
}

export function firestoreQuizCollection() {
  return `${BASE_COLLECTION_NAME.QUIZZES}`;
}
export function firestoreQuizDoc(quizID: string): string {
  return `${BASE_COLLECTION_NAME.QUIZZES}/${quizID}`;
}
export function firestoreQuizChatTopicsCollection(quizID: string): string {
  return `${BASE_COLLECTION_NAME.QUIZZES}/${quizID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreQuizChatTopicsDoc(quizID: string, chatTopicID: string): string {
  return `${BASE_COLLECTION_NAME.QUIZZES}/${quizID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}

// published quiz quiestions
export function firestorePublishedQuizQuestions(p: Quiz) {
  return `${firestorePublishedQuizDoc(p.id)}/${BASE_COLLECTION_NAME.QUESTIONS}`;
}
