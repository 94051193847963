import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable, of, Subject} from 'rxjs';

import {
  convertQuestionLinkToLinksArray,
  createInitialQuizResult,
  createQuizByTest
} from '../quiz.fns';
import {
  Answer,
  CreateReviewQuizFromWrongAnswers,
  QUESTION_TYPE,
  QuestionWithAnswer,
  Quiz,
  ResultAnswer,
  TakeQuizResult,
  UserAnswer
} from '../quiz.model';

@Injectable()
export class QuizResultService extends ComponentStore<TakeQuizResult> {
  private _onDestroy$: Subject<boolean> = new Subject();
  quiz!: Quiz;

  constructor() {
    super(createInitialQuizResult());
  }

  readonly result$: Observable<TakeQuizResult> = this.select(
    (state: TakeQuizResult) => {
      console.log(state);
      return {
        ...state,
        questions: convertQuestionLinkToLinksArray(state.questions)
      };
    }
  );

  readonly createQuizFromWrongAnswers$: Observable<CreateReviewQuizFromWrongAnswers> =
    this.select((state: TakeQuizResult) => {
      return createQuizByTest(state);
    });

  displayedColumns: string[] = ['isCorrect', 'questionText', 'timeToAnswerMS'];
  readonly answers$: Observable<ResultAnswer[]> = this.select(
    (state: TakeQuizResult) => {
      if (state && state.questions) {
        return Object.values<QuestionWithAnswer>(state.questions).map(
          (a: QuestionWithAnswer) =>
            <ResultAnswer>{
              questionText: a.question.question,
              ...a
            }
        );
        // .sort((a: ResultAnswer, b: ResultAnswer) => {
        //   if (b.isCorrect) {
        //     return -1;
        //   }
        //
        //   return 0;
        // });
      }

      return [];
    }
  );

  readonly addResult = this.updater((state, result: TakeQuizResult) => {
    return {
      ...result
    };
  });

  getAnswerList(
    dict: {
      [answerID: string]: UserAnswer;
    } | null
  ): UserAnswer[] {
    if (dict) {
      return Object.values(dict);
    }

    return [];
  }

  getAnswersList$(
    dict: {
      [answerID: string]: UserAnswer;
    } | null
  ): Observable<UserAnswer[]> {
    if (dict) {
      return of(Object.values(dict));
    }

    return of([]);
  }

  getTrueFalseAnswer(
    dict: {
      [answerID: string]: UserAnswer;
    } | null
  ): UserAnswer | null {
    if (dict) {
      return Object.values(dict)[0];
    }

    return null;
  }

  timeToAnswer(ms: number): number {
    return parseInt((ms / 1000).toString(10));
  }

  isTrueFalseQuestion(qt: number) {
    return qt === QUESTION_TYPE.TRUE_FALSE;
  }

  isMultipleChoiceQuestion(qt: number) {
    return qt === QUESTION_TYPE.MULTIPLE_CHOICE;
  }

  isMultipleChoiceCorrect_deprecated(a: UserAnswer) {
    const userChecked =
      a.multipleChoiceChecked !== null && a.multipleChoiceChecked !== undefined
        ? a.multipleChoiceChecked
        : false;

    return userChecked !== a.answer.isCorrect;
  }

  isMultipleChoiceCorrect(a: UserAnswer) {
    const userCheckedAnswer =
      a.multipleChoiceChecked !== null && a.multipleChoiceChecked !== undefined
        ? a.multipleChoiceChecked
        : false;

    const isCorrectAnswer = a.answer.isCorrect;
    const notCorrectAnswer = !isCorrectAnswer;

    if (isCorrectAnswer) {
      return userCheckedAnswer;
    }

    if (notCorrectAnswer) {
      return !userCheckedAnswer;
    }

    return userCheckedAnswer !== a.answer.isCorrect;
  }

  userAnsweredTrueFalseQuestionCorrectly(a: UserAnswer) {
    const userChecked =
      a.trueFalseAnswer !== null && a.trueFalseAnswer !== undefined
        ? a.trueFalseAnswer
        : false;

    return userChecked !== a.answer.trueFalseAnswer;
  }

  isTrueCorrectAnswer(
    dict: {
      [answerID: string]: UserAnswer;
    } | null
  ) {
    if (dict) {
      const userAnswer: Answer | undefined =
        this.getTrueFalseAnswer(dict)?.answer;

      return userAnswer?.trueFalseAnswer === true;
    }

    return false;

    // return userAnswer.trueFalseAnswer === true;
  }

  isFalseCorrectAnswer(
    dict: {
      [answerID: string]: UserAnswer;
    } | null
  ) {
    if (dict) {
      const userAnswer: Answer | undefined =
        this.getTrueFalseAnswer(dict)?.answer;

      // return (
      //   a.answer.trueFalseAnswer === false && userAnswer.trueFalseAnswer === true
      // );

      return userAnswer?.trueFalseAnswer === false;
    }

    return false;
  }

  userAnsweredTrueFalseCorrect(
    dict: {
      [answerID: string]: UserAnswer;
    } | null
  ) {
    if (dict) {
      const userAnswer: UserAnswer | null = this.getTrueFalseAnswer(dict);

      const answer: Answer | undefined = userAnswer?.answer;

      if (userAnswer && answer) {
        return userAnswer.trueFalseAnswer === answer.trueFalseAnswer;
      }
    }

    return false;
  }

  userAnsweredMultipleChoiceCorrect(a: UserAnswer) {
    const answer: Answer = a.answer;

    return a.multipleChoiceChecked === answer.isCorrect;
  }

  initialize(r: TakeQuizResult | undefined | null) {
    if (r) {
      this.quiz = r.quiz;
      this.addResult(r);
    }
  }

  onDestroy() {
    this._onDestroy$.next(true);
    // this.connection.destroy();
  }
}
