import {CollaborativeEntity} from '../entity/entity.model';
import {
  Member,
  MemberUIDBaseEntityDict,
  MemberUIDBaseEntityID
} from './members.model';

export function convertCollaborativeEntityToMemberUIDEntityID(
  groups: CollaborativeEntity[]
): MemberUIDBaseEntityID[] {
  if (groups && groups.length) {
    return groups
      .filter((p: CollaborativeEntity) => p.memberUIDs && p.memberUIDs.length)
      .reduce(
        (a: MemberUIDBaseEntityID[], g: CollaborativeEntity) => {
          const members: MemberUIDBaseEntityID[] = g.memberUIDs.reduce(
            (_a: MemberUIDBaseEntityID[], memberID: string) => {
              _a.push(<MemberUIDBaseEntityID>{
                entities: {
                  [g.id]: {
                    entityID: g.id,
                    role: g.members[memberID].role
                  }
                },
                entityID: g.id,
                uid: memberID
              });

              return _a;
            },
            []
          );

          return [...a, ...members];
        },
        <MemberUIDBaseEntityID[]>[]
      );
  }

  return [];
}

export function concatMember(m: MemberUIDBaseEntityID[]) {
  const dict: MemberUIDBaseEntityDict = m.reduce(
    (a: MemberUIDBaseEntityDict, i: MemberUIDBaseEntityID) => {
      if (!a[i.uid]) {
        a[i.uid] = {
          entities: {},
          entityIDs: [],
          uid: i.uid,
          username: ''
        };
      }

      a[i.uid].entityIDs = <string[]>[
        ...new Set([...a[i.uid].entityIDs, i.entityID])
      ];

      a[i.uid].entities = {
        ...a[i.uid].entities,
        ...i.entities
      };

      return a;
    },
    <MemberUIDBaseEntityDict>{}
  );

  return Object.values(dict);
}

export function removeEntityFromMember(
  _m: Member,
  p: CollaborativeEntity
): Member {
  const m: Member = JSON.parse(JSON.stringify(_m));

  /**
   * If Member includes entity id, but p does not include
   * the member id, from the entity from the member
   */
  if (m.entities[p.id] && !p.memberUIDs.includes(m.uid)) {
    const idIndex = m.entityIDs.indexOf(p.id);

    if (idIndex > -1) {
      m.entityIDs.splice(idIndex, 1);
    }

    delete (<any>m).entities[p.id];
  }

  return m;
}

export function iterateChanges<State>(
  action: any,
  key: string,
  _state: {
    entities: {[uid: string]: Member};
  }
): State {
  for (let i = 0; i < action[key].length; i++) {
    if (action[key][i].changes.isCollaborative) {
      const p: CollaborativeEntity = <CollaborativeEntity>(
        action[key][i].changes
      );

      for (const uid in _state.entities) {
        if (_state.entities[uid]) {
          _state.entities[uid] = removeEntityFromMember(
            _state.entities[uid],
            p
          );
        }
      } // end for
    } // end if
  } // end for

  return (<unknown>_state) as State;
}
