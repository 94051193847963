import {DEFAULT_NAV_ITEM_ID} from '@gigasoftware/shared/domain';

export enum NAV {
  MOBILE_HOME_PAGE = DEFAULT_NAV_ITEM_ID,

  // ADMIN
  MOBILE_ADMIN_SUBJECTS_PAGE = '20',
  MOBILE_ADMIN_USERS_PAGE = '30',

  MOBILE_PROFILE_PAGE = '40',
  MOBILE_LOGIN_PAGE = '50',
  MOBILE_ACCOUNT_PAGE = '60',
  MOBILE_SUBSCRIBE_PAGE = '70',
  MOBILE_SUPPORT_PAGE = '80',
  MOBILE_PRIVACY_POLICY_PAGE = '90',
  MOBILE_TERMS_OF_USE_PAGE = '100',
  MOBILE_ADD_NOTE_PAGE = '110',
  MOBILE_NOTES_PAGE = '120',

  MOBILE_QUIZ_QUESTION_CREATE_PAGE = '1000',
  MOBILE_QUIZ_QUESTION_EDIT_PAGE = '1010',
  MOBILE_QUIZ_CREATE_PAGE = '1020',
  MOBILE_QUIZ_EDIT_PAGE = '1030',
  MOBILE_QUIZ_GRADES_PAGE = '1040',
  MOBILE_QUIZ_STATS_PAGE = '1050',
  MOBILE_QUIZ_LIST_PAGE = '1060',
  MOBILE_QUIZ_REVIEW = '1070',
  MOBILE_QUIZ_TEST_PAGE = '1080',

  MOBILE_STUDY_GROUP_CREATE_PAGE = '2000',
  MOBILE_STUDY_GROUP_LIST_PAGE = '2010',
  MOBILE_STUDY_GROUP_ITEM_PAGE = '2020',
  MOBILE_STUDY_GROUP_EDIT_PAGE = '2030',
  MOBILE_STUDY_GROUP_QUIZ_STATS_PAGE = '2040',
  MOBILE_STUDY_GROUP_QUIZ_CREATE_PAGE = '2050',
  MOBILE_STUDY_GROUP_QUIZ_QUESTION_CREATE_PAGE = '2060',
  MOBILE_STUDY_GROUP_QUIZ_QUESTION_EDIT_PAGE = '2070',
  MOBILE_STUDY_GROUP_QUIZ_EDIT_PAGE = '2080',
  MOBILE_STUDY_GROUP_QUIZ_TEST_PAGE = '2090',
  MOBILE_STUDY_GROUP_QUIZ_GRADES_PAGE = '2100',
  MOBILE_STUDY_GROUP_ALL_GRADES_PAGE = '2110',
  MOBILE_STUDY_GROUP_ADD_NOTE_PAGE = '2120',
  MOBILE_STUDY_GROUP_QUIZ_REVIEW_PAGE = '2130',

  MOBILE_CLASS_CREATE_PAGE = '3000',
  MOBILE_CLASS_LIST_PAGE = '3010',
  MOBILE_CLASS_ITEM_PAGE = '3020',
  MOBILE_CLASS_EDIT_PAGE = '3030',
  MOBILE_CLASS_QUIZ_STATS_PAGE = '3040',
  MOBILE_CLASS_QUIZ_CREATE_PAGE = '3050',
  MOBILE_CLASS_QUIZ_EDIT_PAGE = '3060',
  MOBILE_CLASS_QUIZ_TEST_PAGE = '3070',
  MOBILE_CLASS_QUIZ_QUESTION_CREATE_PAGE = '3080',
  MOBILE_CLASS_QUIZ_QUESTION_EDIT_PAGE = '3090',
  MOBILE_CLASS_QUIZ_STUDENT_GRADES_PAGE = '3100',
  MOBILE_CLASS_ALL_GRADES_PAGE = '3110',
  MOBILE_CLASS_QUIZ_REVIEW_PAGE = '3120',
  MOBILE_CLASS_QUIZ_ALL_STUDENT_GRADES_PAGE = '3130',
  MOBILE_CLASS_ADD_NOTE_PAGE = '3140',

  DASHBOARD_CLASSES_PAGE = '4000',
  DASHBOARD_PERSONAL_QUIZZES_PAGE = '4010',
  DASHBOARD_STUDY_GROUPS_PAGE = '4020',
  DASHBOARD_PROFILE_PAGE = '4030',
  DASHBOARD_ACCOUNT_PAGE = '4040',
  DASHBOARD_SUPPORT_PAGE = '4050',
  DASHBOARD_PRIVACY_POLICY_PAGE = '4060',
  DASHBOARD_TERMS_OF_USE_PAGE = '4070',
  DASHBOARD_STUDY_GROUP_PAGE = '4080',

  MOBILE_INVESTIGATIONS_CREATE_PAGE = '5000',
  MOBILE_INVESTIGATIONS_LIST_PAGE = '5010',
  MOBILE_INVESTIGATIONS_ITEM_PAGE = '5020',
  MOBILE_INVESTIGATIONS_EDIT_PAGE = '5030',
  MOBILE_INVESTIGATIONS_QUIZ_STATS_PAGE = '5040',
  MOBILE_INVESTIGATIONS_QUIZ_CREATE_PAGE = '5050',
  MOBILE_INVESTIGATIONS_QUIZ_QUESTION_CREATE_PAGE = '5060',
  MOBILE_INVESTIGATIONS_QUIZ_QUESTION_EDIT_PAGE = '5070',
  MOBILE_INVESTIGATIONS_QUIZ_EDIT_PAGE = '5080',
  MOBILE_INVESTIGATIONS_QUIZ_TEST_PAGE = '5090',
  MOBILE_INVESTIGATIONS_QUIZ_GRADES_PAGE = '5100',
  MOBILE_INVESTIGATIONS_ALL_GRADES_PAGE = '5110',
  MOBILE_INVESTIGATIONS_ADD_NOTE_PAGE = '5120',
  MOBILE_INVESTIGATIONS_QUIZ_REVIEW_PAGE = '5130',

  MOBILE_PROJECT_CREATE_PAGE = '6000',
  MOBILE_PROJECT_LIST_PAGE = '6010',
  MOBILE_PROJECT_ITEM_PAGE = '6020',
  MOBILE_PROJECT_EDIT_PAGE = '6030',
  MOBILE_PROJECT_QUIZ_STATS_PAGE = '6040',
  MOBILE_PROJECT_QUIZ_CREATE_PAGE = '6050',
  MOBILE_PROJECT_QUIZ_QUESTION_CREATE_PAGE = '6060',
  MOBILE_PROJECT_QUIZ_QUESTION_EDIT_PAGE = '6070',
  MOBILE_PROJECT_QUIZ_EDIT_PAGE = '6080',
  MOBILE_PROJECT_QUIZ_TEST_PAGE = '6090',
  MOBILE_PROJECT_QUIZ_GRADES_PAGE = '6100',
  MOBILE_PROJECT_ALL_GRADES_PAGE = '6110',
  MOBILE_PROJECT_ADD_NOTE_PAGE = '6120',
  MOBILE_PROJECT_QUIZ_REVIEW_PAGE = '6130',

  MOBILE_RESEARCH_CREATE_PAGE = '7000',
  MOBILE_RESEARCH_LIST_PAGE = '7010',
  MOBILE_RESEARCH_ITEM_PAGE = '7020',
  MOBILE_RESEARCH_EDIT_PAGE = '7030',
  MOBILE_RESEARCH_QUIZ_STATS_PAGE = '7040',
  MOBILE_RESEARCH_QUIZ_CREATE_PAGE = '7050',
  MOBILE_RESEARCH_QUIZ_QUESTION_CREATE_PAGE = '7060',
  MOBILE_RESEARCH_QUIZ_QUESTION_EDIT_PAGE = '7070',
  MOBILE_RESEARCH_QUIZ_EDIT_PAGE = '7080',
  MOBILE_RESEARCH_QUIZ_TEST_PAGE = '7090',
  MOBILE_RESEARCH_QUIZ_GRADES_PAGE = '7100',
  MOBILE_RESEARCH_ALL_GRADES_PAGE = '7110',
  MOBILE_RESEARCH_ADD_NOTE_PAGE = '7120',
  MOBILE_RESEARCH_QUIZ_REVIEW_PAGE = '7130',

  MOBILE_JOURNAL_CREATE_PAGE = '8000',
  MOBILE_JOURNAL_LIST_PAGE = '8010',
  MOBILE_JOURNAL_ITEM_PAGE = '8020',
  MOBILE_JOURNAL_EDIT_PAGE = '8030',
  MOBILE_JOURNAL_QUIZ_STATS_PAGE = '8040',
  MOBILE_JOURNAL_QUIZ_CREATE_PAGE = '8050',
  MOBILE_JOURNAL_QUIZ_QUESTION_CREATE_PAGE = '8060',
  MOBILE_JOURNAL_QUIZ_QUESTION_EDIT_PAGE = '8070',
  MOBILE_JOURNAL_QUIZ_EDIT_PAGE = '8080',
  MOBILE_JOURNAL_QUIZ_TEST_PAGE = '8090',
  MOBILE_JOURNAL_QUIZ_GRADES_PAGE = '8100',
  MOBILE_JOURNAL_ALL_GRADES_PAGE = '8110',
  MOBILE_JOURNAL_ADD_NOTE_PAGE = '8120',
  MOBILE_JOURNAL_QUIZ_REVIEW_PAGE = '8130'
}
