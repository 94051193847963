import {
  ChangeDetectionStrategy,
  Component, Input,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';

/**
 * @description
 * A component that displays the current question number and the total number of questions.
 *
 *
 * @example
 * <ec-question-counter [progress]="progress" [total]="total"></ec-question-counter>
 */
@Component({
    selector: 'ec-question-counter',
    imports: [CommonModule],
    templateUrl: './ec-question-progress-counter.component.html',
    styleUrls: ['./ec-question-progress-counter.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'ec-question-counter ec-quiz'
    }
})
export class EcQuestionProgressCounterComponent {

  @Input() progress = 0;
  @Input() total = 0;
}
