import {GSFirebaseUploadImageConfig} from '@gigasoftware/shared/media';
import {GigaNoteDoc} from '@ngpat/firebase';
import {DlcSaveBtnState} from '../../../button/dlc-save-button/dlc-save-button.component';

export enum DlcTranscribeBtnState {
  IN_PROGRESS = 'in-progress',
  DISABLED = 'disabled',
  ACTIVE = 'active',
  PENDING = 'pending'
}

export enum DlcNoteSaveAction {
  SAVE_NOTE_TITLE = 'title', // done
  SAVE_USER_NOTE_VERSION = 'user_note', // done
  SAVE_TRANSCRIPTION_NOTE_VERSION = 'transcription_update', // done
  SAVE_FIRESTORE_DOC = 'doc' // done
}

export interface DlcNoteSaveActionItem {
  id: DlcNoteSaveAction;
}

export interface DlcNoteStore {
  firestoreDoc: GigaNoteDoc | null;
  firestorePath: string | null;
  uuid: string | null;
  isDirty: boolean;
  latestNoteVersion: string | null;
  latestTransitionVersion: string | null;
  uploadConfig: Partial<GSFirebaseUploadImageConfig> | null;
  userNote: string | null;
  transcribedNote: string | null;
  saveBtnState: DlcSaveBtnState;
  transcribedTextId: string;
  userTextId: string;
  // transcribeBtnState: DlcTranscribeBtnState;
}

export interface DlcNoteProcessQueue {
  id: number;
  action: DlcNoteSaveAction;
  userNoteVersion?: string;
  title?: string;
  transcriptionNoteVersion?: string;
  firestoreDoc?: GigaNoteDoc | null;
}

export interface DlcNoteBasePathImageConfig {
  basePath: string;
  uploadImageConfig: Partial<GSFirebaseUploadImageConfig>;
  parentId: string;
}

export interface DlcNoteTranscribedTextIdNull {
  transcribedTextId: string;
  firestorePath: string | null;
}

export interface DlcNoteTranscribedTextId {
  transcribedTextId: string;
  firestorePath: string;
}

export interface DlcNoteUserTextIdNull {
  userTextId: string;
  firestorePath: string | null;
}

export interface DlcNoteUserTextId {
  userTextId: string;
  firestorePath: string;
}

export const addProcessQueueIdFirestoreDoc = (item: DlcNoteProcessQueue): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 0
  };
};

export const addProcessQueueIdNoteTitle = (item: DlcNoteProcessQueue): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 1
  };
};

export const addProcessQueueIdNoteVersion = (item: DlcNoteProcessQueue): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 2
  };
};

export const addProcessQueueIdTranscriptionVersion = (item: DlcNoteProcessQueue): DlcNoteProcessQueue => {
  return {
    ...item,
    id: 3
  };
};
