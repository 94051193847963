import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject, signal, WritableSignal} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatProgressBar} from '@angular/material/progress-bar';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {NgPatNormalizedOffer} from '@ngpat/store';
import {APP} from '../../../../../entites';
import {DlcWebCancelSubscription} from '../../dlc-web-pricing/dlc-web-pricing.models';

export interface DlcCancelWebModalData {
  price: NgPatNormalizedOffer;
  app: APP;
}

@Component({
    selector: 'dlc-cancel-web-subscription-modal',
    imports: [CommonModule, MatDialogModule, MatButton, MatProgressBar],
    templateUrl: './cancel-web-subscription-modal.component.html',
    styleUrl: './cancel-web-subscription-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelWebSubscriptionModalComponent {
  showProgress: WritableSignal<boolean> = signal(false);
  error: WritableSignal<boolean> = signal(false);

  constructor(
    public dialogRef: MatDialogRef<CancelWebSubscriptionModalComponent>,
    private customFirestore: NgPatFirestoreService,
    @Inject(MAT_DIALOG_DATA) public data: DlcCancelWebModalData
  ) {}

  async onConfirm() {
    this.showProgress.set(true);

    const cancelStripeSubscription = this.customFirestore.httpsCallable(
      'cancelStripeSubscriptionV2'
    );

    if (cancelStripeSubscription) {
      cancelStripeSubscription(<DlcWebCancelSubscription>{
        env: this.customFirestore.env,
        app: this.data.app,
        subscriptionId: this.data.price.webPriceSubscriptionId
      })
        .then(() => {
          this.dialogRef.close(true);
        })
        .catch(() => {
          this.error.set(true);
        });
    }
  }
}
