<image-cropper
  [style.display]="showCropper()"
  [canvasRotation]="canvasRotation()"
  [imageFile]="imageFile"
  [aspectRatio]="evaluatedAspectRatio()"
  [containWithinAspectRatio]="containWithinAspectRatio()"
  [transform]="transform()"
  (imageLoaded)="imageLoaded()"
  (imageCropped)="onImageCropped($event)"
  (cropperReady)="cropperReady($event)"
  (transformChange)="transformChange($event)"
  [onlyScaleDown]="false">
</image-cropper>

<div class="dlc-image-cropper__container--controls">
  <div class="dlc-image-cropper__container--controls-group">
    <button
      dlc-rounded-text-icon-button
      color="primary"
      (click)="flipHorizontal()">
      <mat-icon>flip</mat-icon>
      <span>Horizontal</span>
    </button>
    <button
      dlc-rounded-text-icon-button
      color="primary"
      (click)="flipVertical()">
      <mat-icon class="dlc-image-cropper__container--controls-flip-vertical">
        flip</mat-icon
      >
      <span>Vertical</span>
    </button>
  </div>
</div>

<!-- Aspect Ratio -->
<div
  *ngIf="dlcLayout.isXS()"
  class="dlc-image-cropper__container--controls">
  <dlc-label-container>
    <span dlcLabel> Aspect Ratio </span>
    <div class="dlc-image-cropper__container--controls-group">
      <button
        dlc-rounded-text-icon-button
        color="primary"
        (click)="fillAspectRatio()"
        [disabled]="!containWithinAspectRatio()">
        <mat-icon>open_in_full</mat-icon>
        <span>Fill</span>
      </button>

      <button
        dlc-rounded-text-icon-button
        color="primary"
        (click)="containAspectRatio()"
        [disabled]="containWithinAspectRatio()">
        <mat-icon>close_fullscreen</mat-icon>
        <span>Contain</span>
      </button>
    </div>
  </dlc-label-container>
</div>

<div
  *ngIf="dlcLayout.isGTXS()"
  class="dlc-image-cropper__container--controls">
  <div class="dlc-image-cropper__container--controls-group">
    <mat-form-field
      appearance="outline"
      color="primary"
      class="dlc-image-cropper__container--controls-aspect-ratio"
      *ngIf="showAspectRatioSelectorSignal()">
      <mat-label> Aspect Ratio </mat-label>
      <mat-select
        [value]="selectedAspectRatio()"
        (selectionChange)="selectedAspectRatio.set($event.value)">
        <mat-option
          [value]="option"
          *ngFor="let option of aspectRatios">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <dlc-label-container *ngIf="!showAspectRatioSelectorSignal()">
      <span dlcLabel> Aspect Ratio </span>
      <div class="dlc-image-cropper__container--controls-group">
        <button
          dlc-rounded-text-icon-button
          color="primary"
          (click)="fillAspectRatio()"
          [disabled]="!containWithinAspectRatio()">
          <mat-icon>open_in_full</mat-icon>
          <span>Fill</span>
        </button>

        <button
          dlc-rounded-text-icon-button
          color="primary"
          (click)="containAspectRatio()"
          [disabled]="containWithinAspectRatio()">
          <mat-icon>close_fullscreen</mat-icon>
          <span>Contain</span>
        </button>
      </div>
    </dlc-label-container>

    <ng-container *ngIf="showAspectRatioSelectorSignal()">
      <button
        dlc-rounded-text-icon-button
        color="primary"
        (click)="fillAspectRatio()"
        [disabled]="!containWithinAspectRatio()">
        <mat-icon>open_in_full</mat-icon>
        <span>Fill</span>
      </button>

      <button
        dlc-rounded-text-icon-button
        color="primary"
        (click)="containAspectRatio()"
        [disabled]="containWithinAspectRatio()">
        <mat-icon>close_fullscreen</mat-icon>
        <span>Contain</span>
      </button>
    </ng-container>
  </div>
</div>

<!-- ZOOM -->
<div
  *ngIf="dlcLayout.isXS()"
  class="dlc-image-cropper__container--controls">
  <dlc-label-container>
    <span dlcLabel>Zoom</span>
    <div class="dlc-image-cropper__container--controls-group">
      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="zoomOut()">
        <mat-icon>remove</mat-icon>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="zoomIn()">
        <mat-icon>add</mat-icon>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="zoom100()"
        [disabled]="disableIfZoomIs100()">
        100%
      </button>
    </div>
  </dlc-label-container>
</div>

<div
  *ngIf="dlcLayout.isGTXS()"
  class="dlc-image-cropper__container--controls">
  <div class="dlc-image-cropper__container--controls-group">
    <mat-form-field
      class="rotation"
      appearance="outline"
      *ngIf="showZoomInputSignal()">
      <mat-label>Zoom</mat-label>
      <input
        dlcInput
        [formControl]="zoomForm" />
      <div dlcTextSuffix>%</div>
    </mat-form-field>

    <dlc-label-container *ngIf="!showZoomInputSignal()">
      <span dlcLabel>Zoom</span>
      <div class="dlc-image-cropper__container--controls-group">
        <button
          dlc-rounded-icon-button
          color="primary"
          (click)="zoomOut()">
          <mat-icon>remove</mat-icon>
        </button>

        <button
          dlc-rounded-icon-button
          color="primary"
          (click)="zoomIn()">
          <mat-icon>add</mat-icon>
        </button>

        <button
          dlc-rounded-icon-button
          color="primary"
          (click)="zoom100()"
          [disabled]="disableIfZoomIs100()">
          100%
        </button>
      </div>
    </dlc-label-container>

    <ng-container *ngIf="showZoomInputSignal()">
      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="zoomOut()">
        <mat-icon>remove</mat-icon>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="zoomIn()">
        <mat-icon>add</mat-icon>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="zoom100()"
        [disabled]="disableIfZoomIs100()">
        100%
      </button>
    </ng-container>
  </div>
</div>

<!-- ROTATION -->
<div
  *ngIf="dlcLayout.isXS()"
  class="dlc-image-cropper__container--controls">
  <dlc-label-container>
    <span dlcLabel>Rotation</span>
    <div class="dlc-image-cropper__container--controls-group">
      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="rotateLeft()">
        <mat-icon>undo</mat-icon>
        <span>90 deg</span>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="rotateRight()">
        <mat-icon>redo</mat-icon>
        <span>90 deg</span>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="rotate0()"
        [disabled]="disableIfRotateIs0()">
        0 deg
      </button>
    </div>
  </dlc-label-container>
</div>

<div
  *ngIf="dlcLayout.isGTXS()"
  class="dlc-image-cropper__container--controls">
  <div class="dlc-image-cropper__container--controls-group">
    <mat-form-field
      *ngIf="showRotationInputSignal()"
      class="rotation"
      appearance="outline">
      <mat-label>Rotation</mat-label>
      <input
        matInput
        [formControl]="rotationForm" />
      <div matTextSuffix>deg</div>
    </mat-form-field>

    <dlc-label-container *ngIf="!showRotationInputSignal()">
      <span dlcLabel>Rotation</span>
      <div class="dlc-image-cropper__container--controls-group">
        <button
          dlc-rounded-icon-button
          color="primary"
          (click)="rotateLeft()">
          <mat-icon>undo</mat-icon>
          <span>90 deg</span>
        </button>

        <button
          dlc-rounded-icon-button
          color="primary"
          (click)="rotateRight()">
          <mat-icon>redo</mat-icon>
          <span>90 deg</span>
        </button>

        <button
          dlc-rounded-icon-button
          color="primary"
          (click)="rotate0()"
          [disabled]="disableIfRotateIs0()">
          0 deg
        </button>
      </div>
    </dlc-label-container>

    <ng-container *ngIf="showRotationInputSignal()">
      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="rotateLeft()">
        <mat-icon>undo</mat-icon>
        <span>90 deg</span>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="rotateRight()">
        <mat-icon>redo</mat-icon>
        <span>90 deg</span>
      </button>

      <button
        dlc-rounded-icon-button
        color="primary"
        (click)="rotate0()"
        [disabled]="disableIfRotateIs0()">
        0 deg
      </button>
    </ng-container>
  </div>
</div>

<div class="dlc-image-cropper__container--controls">
  <div class="dlc-image-cropper__container--controls-group">
    <button
      dlc-rounded-icon-button
      color="primary"
      (click)="resetImage()"
      [disabled]="disableResetImage()">
      Reset
    </button>
  </div>
</div>

<div
  *ngIf="loading()"
  class="dlc-image-cropper__progress absolute inset-0">
  <mat-spinner></mat-spinner>
</div>
