@if (titleHasValue()) {
  <div class="ml-[1px] font-normal text-[12px] dlc-syncfusion-rte-title">
    {{ title() }}
  </div>
}
<form [formGroup]="rteForm">
  <ejs-richtexteditor
    #fromRTE
    id="alltoolRTE"
    formControlName="text"
    [toolbarSettings]="tools"
    [showCharCount]="true"
    [enableXhtml]="true"
    [saveInterval]="100"
    [quickToolbarSettings]="quickToolbarSettings"
    (actionBegin)="actionBeginHandler($event)"
    (actionComplete)="actionCompleteHandler($event)"
    (created)="createdHandler()"
    [enableTabKey]="true"
    [placeholder]="placeholder()"
    [slashMenuSettings]="slashMenuSettings">
    <ng-template #valueTemplate>
      <p>My Note</p>
    </ng-template>
  </ejs-richtexteditor>
</form>
