import {inject, Injectable} from '@angular/core';
import {EC_HTTPS_CALLABLE, firestoreNoteVersionDoc} from '@gigasoftware/evolving-cognition/domain';
import {
  GigaNoteDoc,
  GigaNoteTranscribeNoteVersionUpdate,
  GigaUserNoteVersion,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {jsonPopulated, ngPatWithLastValueFrom} from '@ngpat/rxjs';
import firebase from 'firebase/compat';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {ofDlcNoteActionTypeWithFirestoreDoc} from './note.fns';
import {DlcNoteProcessQueue, DlcNoteSaveAction, DlcNoteUserTextId, DlcNoteUserTextIdNull} from './note.model';
import {DlcNoteStoreService} from './note.store';
import HttpsCallableResult = firebase.functions.HttpsCallableResult;

@Injectable()
export class DlcUserNoteService {
  private _onDestroy$: Subject<boolean> = new Subject();
  private store = inject(DlcNoteStoreService);
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  constructor() {
    // GET LATEST VERSION OF USER NOTE
    this.store.selectUserTextId$
      .pipe(
        takeUntil(this._onDestroy$),
        filter((doc: DlcNoteUserTextIdNull) => (doc && doc.firestorePath && doc.userTextId.length > 0) as boolean),
        map((doc: DlcNoteUserTextIdNull) => {
          return doc as DlcNoteUserTextId;
        }),
        map((doc: DlcNoteUserTextId) => firestoreNoteVersionDoc(doc.firestorePath, doc.userTextId)),
        this.firestore.onSnapshotDoc$<GigaUserNoteVersion | null | undefined>(),
        jsonPopulated<GigaUserNoteVersion>(),
        map((doc: GigaUserNoteVersion) => doc.text)
      )
      .subscribe((doc: string) => {
        // console.log(doc, 'user note');
        this.store.updateLatestNoteVersion(doc);
      });

    this.store.saveDataProcessQueue.currentItem$
      .pipe(
        takeUntil(this._onDestroy$),
        // tap(action => {
        //   console.log(action);
        // }),
        ofDlcNoteActionTypeWithFirestoreDoc(DlcNoteSaveAction.SAVE_USER_NOTE_VERSION),
        ngPatWithLastValueFrom(this.store.selectFirestoreDoc$)
      )
      .subscribe(async ([action, doc]: [DlcNoteProcessQueue, GigaNoteDoc | null]) => {
        const payload = <GigaNoteTranscribeNoteVersionUpdate>{
          doc: {
            ...doc,
            ...action.firestoreDoc
          },
          text: action.userNoteVersion
        };

        const result = await this.addUserNoteVersion(payload);

        await this.store.saveDataProcessQueue.next(action.id);
      });
  }

  async addUserNoteVersion(payload: GigaNoteTranscribeNoteVersionUpdate): Promise<HttpsCallableResult | null> {
    const firebaseCallableFunction = this.firestore.httpsCallable(EC_HTTPS_CALLABLE.ADD_USER_NOTES_VERSSION);

    return await firebaseCallableFunction(payload);
  }

  init() {
    // stub
  }

  onDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }
}
