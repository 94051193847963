import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

/**
 * Optional Generic Wrapper Interface to
 * use as a queue item structure.
 *
 */
export interface NgPatQueueItem<T> {
  type: string;
  config: T;
}

/**
 * A function that compares two items and returns a boolean
 * to determine if an id or item is contained the queue.
 */
export type NgPatProcessQueueFindFn = (a: any, b: any, id?: string) => boolean;

/**
 * A function that compares two items and returns a boolean
 * to determine if an id or item is contained the queue.
 *
 * This is the default find function that assumes the queue
 * is a collection of objects. You can optionally pass in
 * an id to compare the objects by a specific property.
 */
export function ngPatProcessQueueFindFnByKey<T>(a: any, b: any, id?: string): boolean {
  if (id) {
    return a[id] === b[id];
  }

  return a === b;
}

/**
 * An Async Iterator that processes a queue of items.
 */
export class NgPatProcessQueue<T> {
  private _queue: T[] = [];
  private _allProcessingItems: T[] = [];
  private _allProcessingItems$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  private _currentItem$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);

  private _notificationMap: Map<T, () => void> = new Map();
  private _notifyNextItemWhenDone: (() => void) | null | undefined = null;

  // paused: WritableSignal<string> = signal('false');
  //
  // isPaused: Signal<boolean> = computed(() => {
  //   return this.paused() === 'true';
  // });

  currentItem$: Observable<T> = <Observable<T>>this._currentItem$.asObservable().pipe(
    filter((i: T | null) => {
      return i !== null;
    })
  );

  queue$: Observable<T[]> = <Observable<T[]>>this._allProcessingItems$.asObservable();

  private _findFn: NgPatProcessQueueFindFn = ngPatProcessQueueFindFnByKey;

  get findFn(): NgPatProcessQueueFindFn {
    return this._findFn;
  }

  set findFn(value: NgPatProcessQueueFindFn) {
    this._findFn = value;
  }

  private _key: string | undefined;

  get key(): string | undefined {
    return this._key;
  }

  set key(value: string | undefined) {
    this._key = value;
  }

  get hasItems(): boolean {
    return this._queue.length > 0;
  }

  isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isProcessing(): boolean {
    return this.isProcessing$.getValue();
  }

  // set isProcessing(value: boolean) {
  //   this.isProcessing$.next(value);
  // }

  // isProcessing: WritableSignal<boolean> = signal(false);

  isEmpty$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  // isEmpty: WritableSignal<boolean> = signal(true);

  paused$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get paused(): boolean {
    return this.paused$.getValue();
  }

  set paused(value: boolean) {
    this.paused$.next(value);
  }

  constructor() {
    // this.isProcessing$.pipe(distinctUntilChanged()).subscribe((isProcessing: boolean) => {
    //   this.isProcessing.set(isProcessing);
    // });
    //
    // this.isEmpty$.pipe(distinctUntilChanged()).subscribe((isEmpty: boolean) => {
    //   this.isEmpty.set(isEmpty);
    // });
  }

  addItem(item: T | T[]) {
    this.addItems(item);
  }

  /**
   *
   * @param items
   * @param notifyWhenDoneCallback
   */
  addItems(items: T[] | T, notifyWhenDoneCallback?: () => void) {
    if (Array.isArray(items)) {
      this._queue = [...this._queue, ...items];
    } else {
      this._queue = [...this._queue, items];
    }

    if (notifyWhenDoneCallback) {
      // get copy by reference of last item
      const lastItem = this._queue[this._queue.length - 1];
      this._notificationMap.set(lastItem, notifyWhenDoneCallback);
    }

    this.next();
  }

  addUnique(item: any) {
    const found = this._queue.find((a: any) => {
      return this.findFn(item, a, this.key);
    });

    if (!found) {
      this.addItems(item);
    }
  }

  addUniques(items: any[]) {
    items.forEach((i: any) => {
      this.addUnique(i);
    });
  }

  /**
   * TODO add current processing concept
   */
  next() {
    // includes the current item processing

    const nextItem = this._queue.shift();

    // check if has notification callback
    if (nextItem && this._notificationMap.has(nextItem)) {
      this._notifyNextItemWhenDone = this._notificationMap.get(nextItem);
      this._notificationMap.delete(nextItem);
    }

    if (nextItem) {
      this.isProcessing$.next(true);
      this.isEmpty$.next(false);
      this._currentItem$.next(nextItem);
      this._allProcessingItems = [...this._allProcessingItems, nextItem];
    } else {
      this.isProcessing$.next(false);
      this.isEmpty$.next(true);
      this._allProcessingItems = [];
      if (this._notifyNextItemWhenDone) {
        this._notifyNextItemWhenDone();
        this._notifyNextItemWhenDone = null;
      }
    }

    this._allProcessingItems$.next([...this._allProcessingItems]);
  }

  /**
   * Pauses the queue from processing.
   * Does not clear the queue and will
   * not stop the current item from
   * processing.
   */
  pause(bool = true) {
    // this.paused.set('true');
    if (bool) {
      this.paused = true;
    } else {
      this.resume();
    }
    // this.isProcessing = false;
  }

  /**
   * Resumes the queue processing.
   * If the queue is not paused, this
   * will have no effect.
   *
   * If the queue is paused and the
   * current item is processing, this
   * will have no effect.
   *
   * If the queue is paused and the
   * current item is not processing,
   * the next item will be processed.
   */
  resume(bool = true) {
    if (bool) {
      // this.paused.set('false');
      this.paused = false;

      if (!this.isProcessing) {
        this.next();
      }
    } else {
      this.pause();
    }
  }
}
