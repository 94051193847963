<div>
  <div class="!p-0 mb-4">
    <div class="flex flex-row justify-start p-4 w-full">
      <button
        class="mr-4"
        *ngIf="googleAuthProvider() !== null"
        dlc-rounded-text-icon-button
        [disabled]="disableLogin()"
        (click)="login(googleAuthProvider())">
        <mat-icon svgIcon="firebase:google"></mat-icon> Google
      </button>
      <button
        class="mr-4"
        *ngIf="appleAuthProvider() !== null"
        dlc-rounded-text-icon-button
        [disabled]="disableLogin()"
        (click)="login(appleAuthProvider())">
        <mat-icon svgIcon="firebase:apple"></mat-icon> Apple
      </button>
    </div>
  </div>

  <div class="border-t border-slate-700 !p-0 mt-4">
    <div
      class="error-message"
      [ngClass]="{'has-message': errorMessage()?.length}">
      {{ errorMessage() }}
    </div>

    <mat-tab-group
      class="mt-4"
      dynamicHeight
      *ngIf="showEmailAuthenticationForm()">
      <mat-tab label="Sign in">
        <form
          [formGroup]="emailSignInForm"
          class="email-sign-in-form p-4">
          <mat-form-field class="mt-4">
            <input
              matInput
              formControlName="email"
              placeholder="Email"
              autocomplete="email"
              type="email" />
            <mat-error *ngIf="emailSignInForm.get('email')?.invalid">{{
              getEmailErrorMessage(emailSignInForm.get("email"))
            }}</mat-error>
          </mat-form-field>
          <mat-form-field class="mt-8">
            <input
              matInput
              formControlName="password"
              placeholder="Password"
              autocomplete="current-password"
              [type]="signInPasswordVisibilityMode()" />
            <button
              dlcSuffix
              dlc-rounded-icon-button
              (click)="
                $event.stopImmediatePropagation();
                signInPasswordVisibilityMode.set(
                  togglePasswordVisibility(signInPasswordVisibilityMode())
                )
              ">
              <mat-icon svgIcon="firebase:visibility"></mat-icon>
            </button>
            <mat-error *ngIf="emailSignInForm.get('password')?.invalid">{{
              passwordErrorMessage(emailSignInForm.get("password"))
            }}</mat-error>
          </mat-form-field>
          <button
            class="mt-8"
            dlc-rounded-text-button
            color="primary"
            (click)="loginWithEmail()"
            [disabled]="emailSignInFormInValid()">
            Log In
          </button>
        </form>
      </mat-tab>
      <mat-tab label="Register">
        <form
          [formGroup]="emailRegisterForm"
          class="email-sign-in-form p-4">
          <mat-form-field
            class="mt-4"
            *ngIf="showUsernameInput()">
            <input
              matInput
              formControlName="username"
              placeholder="Username"
              autocomplete="off"
              type="text" />
          </mat-form-field>

          <mat-form-field
            [ngClass]="{
              'mt-8': showUsernameInput(),
              'mt-4': !showUsernameInput()
            }">
            <input
              matInput
              formControlName="email"
              placeholder="Email"
              autocomplete="off"
              type="email" />
            <mat-error *ngIf="emailRegisterForm?.get('email')?.invalid">{{
              getEmailErrorMessage(emailRegisterForm.get("email"))
            }}</mat-error>
          </mat-form-field>
          <mat-form-field class="mt-8">
            <input
              matInput
              formControlName="password"
              placeholder="Password"
              autocomplete="off"
              [type]="registrationPasswordVisibilityMode()" />
            <button
              dlcSuffix
              dlc-rounded-icon-button
              (click)="
                $event.stopImmediatePropagation();
                registrationPasswordVisibilityMode.set(
                  togglePasswordVisibility(registrationPasswordVisibilityMode())
                )
              ">
              <mat-icon svgIcon="firebase:visibility"></mat-icon>
            </button>
            <mat-error *ngIf="emailRegisterForm?.get('password')?.invalid">{{
              passwordErrorMessage(emailRegisterForm.get("password"))
            }}</mat-error>
          </mat-form-field>
          <button
            class="mt-8"
            (click)="registerWithEmail()"
            [disabled]="emailRegistrationFormInValid() || disableLogin()"
            dlc-rounded-text-button
            color="primary">
            Register
          </button>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="border-t border-slate-700 !p-0">
    <div class="flex flex-row justify-start p-4 w-full">
      <button
        dlc-rounded-text-button
        color="accent"
        (click)="cancelLogin()">
        Cancel
      </button>
    </div>
  </div>
</div>
