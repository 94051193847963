import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule
} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {DomSanitizer} from '@angular/platform-browser';
// import {GoogleAuthProvider} from '@firebase/auth';
import {NgPatFirestoreService} from '@ngpat/firebase';
import {Store} from '@ngrx/store';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  User
} from 'firebase/auth';

import {DlcRoundedIconButtonComponent} from '../../components/button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {DlcRoundedTextButtonComponent} from '../../components/button/dlc-rounded-text-button/dlc-rounded-text-button.component';
import {DlcRoundedTextIconButtonComponent} from '../../components/button/dlc-rounded-text-icon-button/dlc-rounded-text-icon-button.component';
import {
  cancelAuth,
  clearAuthState,
  updateAuthAction
} from '../auth-login/+login/login.actions';
import {AuthLoginAction} from '../auth-login/+login/login.model';
import {selectCanNotLogin} from '../auth-login/+login/login.selectors';
import {
  NG_PAT_AUTH_PROVIDERS_CONFIG,
  NgPatAuthProvidersConfig,
  PasswordVisibility
} from './ng-pat-firebase-material-ui.models';
import {NgPatFirebaseAssets} from './ng-pat-firebase-material-ui-assets';
import {createAppleAuthProvider} from './providers/create-apple-auth-provider';
import {
  createEmailRegistrationForm,
  createEmailSignInForm,
  showEmailAuthenticationForm,
  statusChanges
} from './providers/create-email-authentication';
import {createGoogleAuthProvider} from './providers/create-google-auth-provider';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-firebase-login'
  },
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DlcRoundedIconButtonComponent,
    DlcRoundedTextButtonComponent,
    DlcRoundedTextIconButtonComponent,
    MatCardModule,
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  selector: 'dlc-firebase-login',
  styleUrls: ['./ng-pat-firebase-material-ui.component.scss'],
  templateUrl: './ng-pat-firebase-material-ui.component.html'
})
export class NgPatFirebaseLoginComponent implements OnInit {
  config: NgPatAuthProvidersConfig = inject(NG_PAT_AUTH_PROVIDERS_CONFIG);
  /**
   * Inject Dependencies
   */
  firebaseService = inject(NgPatFirestoreService);
  appleAuthProvider: Signal<OAuthProvider | null> = computed(() =>
    createAppleAuthProvider(this.firebaseService.auth, this.config.apple)
  );
  disableLogin: Signal<boolean> = this.store.selectSignal(selectCanNotLogin);
  formBuilder = inject(FormBuilder);

  emailRegisterForm = createEmailRegistrationForm(
    this.formBuilder,
    this.config.email
  );
  emailRegistrationFormInValid: Signal<boolean | undefined> = toSignal(
    statusChanges(this.emailRegisterForm)
  );

  /**
   * Email Authentication
   */
  emailSignInForm = createEmailSignInForm(this.formBuilder);
  emailSignInFormInValid = toSignal(statusChanges(this.emailSignInForm));

  errorMessage = signal<string | null>(null);
  /**
   * Google Authentication
   */
  googleAuthProvider: Signal<GoogleAuthProvider | null> = computed(() =>
    createGoogleAuthProvider(this.firebaseService.auth, this.config.google)
  );
  iconRegistry = inject(MatIconRegistry);
  /**
   * User
   */
  @Output() loggedIn = new EventEmitter<User | null>();

  registrationPasswordVisibilityMode: WritableSignal<PasswordVisibility> =
    signal('password');

  sanitizer = inject(DomSanitizer);

  showEmailAuthenticationForm = computed(() =>
    showEmailAuthenticationForm(this.config.email)
  );

  @Input() showHeader = true;

  showUsernameInput = computed(
    () => this.config.email && this.config.email.username
  );

  signInPasswordVisibilityMode: WritableSignal<PasswordVisibility> =
    signal('password');

  @Input() title = 'Signing In';

  constructor(private store: Store) {
    this.iconRegistry.addSvgIconSetLiteralInNamespace(
      'firebase',
      this.sanitizer.bypassSecurityTrustHtml(NgPatFirebaseAssets)
    );
  }

  cancelLogin() {
    this.store.dispatch(cancelAuth());
    this.store.dispatch(clearAuthState());
    // setTimeout(() => {
    //   window.close();
    // }, 1000);
  }

  getEmailErrorMessage(control: AbstractControl | null | undefined) {
    if (control?.hasError('required')) {
      return 'You must enter a value';
    }

    return control?.hasError('email') ? 'Not a valid email' : '';
  }

  /**
   * For Google, see https://firebase.google.com/docs/auth/web/google-signin.
   * For Facebook, see https://firebase.google.com/docs/auth/web/facebook-login.
   * For Twitter, see https://firebase.google.com/docs/auth/web/twitter-login.
   * For Github, see https://firebase.google.com/docs/auth/web/github-auth.
   * For Microsoft, see https://firebase.google.com/docs/auth/web/microsoft-oauth.
   * For Yahoo, see https://firebase.google.com/docs/auth/web/yahoo-oauth.
   * For Apple, see https://firebase.google.com/docs/auth/web/apple.
   * For Phone, see https://firebase.google.com/docs/auth/web/phone-auth.
   * @param provider
   */
  login(provider: null | GoogleAuthProvider) {
    if (!provider) {
      return;
    }

    this.store.dispatch(updateAuthAction({action: AuthLoginAction.LOGGING_IN}));

    if (this.config.signInWithRedirect) {
      signInWithRedirect(this.firebaseService.auth, provider);
    } else {
      signInWithPopup(this.firebaseService.auth, provider)
        .then(() => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential?.accessToken;
          // The signed-in user info.
          // const user = result.user;
          this.errorMessage.set(null);
          // this.loggedIn.emit(user);
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        })
        .catch(error => {
          // Handle Errors here.
          // const errorCode = error.code;
          // const errorMessage = error.message;
          this.errorMessage.set(error.message);
          // The email of the user's account used.
          // const email = error.customData.email;
          // The AuthCredential type that was used.
          // const credential = GoogleAuthProvider.credentialFromError(error);
          // ...
        });
    }
  }

  loginWithEmail() {
    if (!this.emailSignInForm.valid) {
      return;
    }

    const {email, password} = this.emailSignInForm.value;
    signInWithEmailAndPassword(this.firebaseService.auth, email, password)
      .then(() => {
        this.emailSignInForm.reset();
        this.errorMessage.set(null);
      })
      .catch(error => {
        this.errorMessage.set(error.message);
      });
  }

  ngOnInit() {
    onAuthStateChanged(this.firebaseService.auth, user => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        // const uid = user.uid;
        this.loggedIn.emit(user);
        this.errorMessage.set(null);
        // ...
      } else {
        // User is signed out
        // ...
        this.loggedIn.emit(null);
      }
    });
  }

  passwordErrorMessage(control: AbstractControl | null | undefined) {
    if (control?.hasError('required')) {
      return 'You must enter a value';
    }

    return '';
  }

  registerWithEmail() {
    if (!this.emailRegisterForm.valid) {
      return;
    }

    const {email, password} = this.emailRegisterForm.value;

    if (this.emailRegisterForm.value?.username) {
      localStorage.setItem('username', this.emailRegisterForm.value.username);
    }

    createUserWithEmailAndPassword(this.firebaseService.auth, email, password)
      .then(() => {
        // Signed in
        this.errorMessage.set(null);
        this.emailRegisterForm.reset();
        // ...
      })
      .catch(error => {
        // const errorCode = error.code;
        // const errorMessage = error.message;
        this.errorMessage.set(error.message);
        // ..
      });
  }

  togglePasswordVisibility(mode: PasswordVisibility) {
    return mode === 'password' ? 'text' : 'password';
  }
}
