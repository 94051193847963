import {Injectable} from '@angular/core';
import {
  Exists,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {
  assignDeprecatedBaseEntitiesProperties,
  BaseEntity
} from '../entity/entity.model';
import {
  firestoreQueryPathByEntity,
  firestoreQuizCollection,
  firestoreInvestigationCollection,
  firestoreUserInvestigationCollection
} from '../firebase/database-paths';
import {QueryEngineCache} from '../services/query-engine-cache';
import {
  deleteInvestigations,
  updateInvestigations,
  upsertInvestigations
} from './investigation.actions';
import {Investigation} from './investigation.model';
import {investigationFeatureKey} from './investigation.reducer';
import {selectAllInvestigations} from './investigation.selectors';

@Injectable({
  providedIn: 'root'
})
export class InvestigationService implements NgPatFirebaseConnectionService {
  private _investigationQuizQueryCache!: QueryEngineCache<Quiz>;
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Investigation>;
  private _queryPrivateQuizService!: NgPatFirestoreCollectionQuery<Investigation>;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );
  connectionKey = investigationFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  deleteDoc$(classroom: BaseEntity | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserInvestigationCollection(uid)
        : firestoreInvestigationCollection();

      // const path = investigation.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: BaseEntity | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserInvestigationCollection(uid)
        : firestoreInvestigationCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }

    return of(true);
  }

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<Investigation>(
        {
          deleteManyAction: (ids: string[]) => deleteInvestigations({ids}),
          queryMember: true,
          updateManyAction: (investigations: Investigation[]) =>
            updateInvestigations({
              investigations: aggregateUpdates(
                assignDeprecatedBaseEntitiesProperties(
                  investigations
                ) as Investigation[]
              )
            }),
          upsertManyAction: (investigations: Investigation[]) =>
            upsertInvestigations({
              investigations: assignDeprecatedBaseEntitiesProperties(
                investigations
              ) as Investigation[]
            })
        },
        this.store,
        this.customFirestoreService
      );

    // TODO Not needed
    const queryInvestigationConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteInvestigations({ids}),
        queryMember: false,
        updateManyAction: (investigations: Investigation[]) =>
          updateInvestigations({
            investigations: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(
                investigations
              ) as Investigation[]
            )
          }),
        upsertManyAction: (investigations: Investigation[]) =>
          upsertInvestigations({
            investigations: assignDeprecatedBaseEntitiesProperties(
              investigations
            ) as Investigation[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateQuizService =
      queryInvestigationConfig.createFirestoreCollectionQuery();

    const queryQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteQuizs({ids}),
        queryMember: false,
        updateManyAction: (quizs: Quiz[]) =>
          updateQuizs({
            quizs: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
            )
          }),
        upsertManyAction: (quizs: Quiz[]) =>
          upsertQuizs({
            quizs: assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (entity: Quiz, uid: string) =>
      `${firestoreQueryPathByEntity(entity as BaseEntity, uid)}/${firestoreQuizCollection()}`;

    this._investigationQuizQueryCache = new QueryEngineCache<Quiz>(
      queryQuizConfig,
      this.store,
      selectAllInvestigations,
      quizPathGenerator,
      'id'
    );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreInvestigationCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateQuizService.onConnect(
      firestoreUserInvestigationCollection(<string>user.uid),
      null,
      null
    );
    this._investigationQuizQueryCache.onConnect(user);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateQuizService.onDisconnect();
    this._investigationQuizQueryCache.onDisconnect();

    // Unsubscribe to query before calling this
  }

  updateDoc(g: Investigation) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByEntity(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(
    changes: Partial<Investigation>,
    quiz: Investigation,
    uid: string | null
  ): Observable<Investigation> {
    if (uid) {
      return this.customFirestoreService
        .merge$<Investigation>(firestoreQueryPathByEntity(quiz, uid), changes)
        .pipe(map((r: Exists<Investigation>) => r.data));
    }

    return of(quiz);
  }
}
