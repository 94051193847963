@if (blobSignal()) {
  <img
    class="dlc-input-image__container--image"
    dlc-blob-image
    [blob]="blobSignal()"
    alt="Select Image" />
}

@if (!blobSignal()) {
  <dlc-input-file
    [mediaType]="'image'"
    (urlChange)="onInputUrl()"
    (errorChange)="onInputError($event)"
    (fileChange)="onInputBlob($event)"></dlc-input-file>
}

<div class="dlc-input-image__container--footer edit">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="onDeleteImageSet($event)">
    <mat-icon>delete</mat-icon>&nbsp;Delete
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="openFileBrowser()">
    <mat-icon>photo_camera</mat-icon>&nbsp;Update
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="editImage($event)">
    <mat-icon>edit</mat-icon>&nbsp;Edit
  </button>
</div>

<div class="dlc-input-image__container--footer save-cancel">
  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="onCancelEdit($event)">
    <mat-icon>cancel</mat-icon>&nbsp;Cancel
  </button>

  <button
    dlc-rounded-text-icon-button
    color="primary"
    (click)="onSaveEdit($event)">
    <mat-icon>save</mat-icon>&nbsp;Save
  </button>
</div>

<div class="dlc-input-image__container--footer uploading">
  <div>Uploading</div>
  <mat-progress-spinner
    mode="determinate"
    diameter="20"
    strokeWidth="4"
    [value]="assetService.uploadProgress()"></mat-progress-spinner>
</div>

<div class="dlc-input-image__container--footer downloading">
  <div>Downloading</div>
  <mat-progress-spinner
    mode="indeterminate"
    diameter="20"
    strokeWidth="4"></mat-progress-spinner>
</div>

<div class="dlc-input-image__container--footer deleting">
  <div>Deleting</div>
  <mat-progress-spinner
    mode="indeterminate"
    diameter="20"
    strokeWidth="4"></mat-progress-spinner>
</div>

<div class="dlc-input-image__container--footer error">
  <div>{{ inputErrorSignal() }}</div>
</div>

<input
  type="file"
  #fileInput
  (change)="onUpdateImage($event)"
  [attr.accept]="acceptImage"
  class="dlc-input-image__container--input" />
