import {ngPatLogout} from '@ngpat/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {createReducer, on} from '@ngrx/store';

import {resetNavigation} from '../+ui/ui.actions';
import * as ResearchActions from './research.actions';
import {Research} from './research.model';

export const researchFeatureKey = 'research';

export interface ResearchState extends EntityState<Research> {
  // additional entities state properties
  selectedResearchID: string | null;
}

export interface PartialResearchState {
  readonly [researchFeatureKey]: ResearchState;
}

export const researchAdapter: EntityAdapter<Research> =
  createEntityAdapter<Research>();

export const initialResearchState: ResearchState =
  researchAdapter.getInitialState({
    // additional entity state properties
    selectedResearchID: null
  });

export const reducer = createReducer(
  initialResearchState,
  on(ResearchActions.addResearch, (state, action) =>
    researchAdapter.addOne(action.research, state)
  ),
  on(ResearchActions.setResearch, (state, action) =>
    researchAdapter.setOne(action.research, state)
  ),
  on(ResearchActions.addResearchs, (state, action) =>
    researchAdapter.addMany(action.researchs, state)
  ),
  on(ResearchActions.updateResearch, (state, action) =>
    researchAdapter.updateOne(action.research, state)
  ),
  on(ResearchActions.updateResearchs, (state, action) =>
    researchAdapter.updateMany(action.researchs, state)
  ),
  on(ResearchActions.upsertResearch, (state, action) =>
    researchAdapter.upsertOne(action.research, state)
  ),
  on(ResearchActions.upsertResearchs, (state, action) =>
    researchAdapter.upsertMany(action.researchs, state)
  ),
  on(ResearchActions.mapResearch, (state, {entityMap}) => {
    return researchAdapter.mapOne(entityMap, state);
  }),
  on(ResearchActions.mapResearchs, (state, {entityMap}) => {
    return researchAdapter.map(entityMap, state);
  }),
  on(ResearchActions.deleteResearch, (state, action) =>
    researchAdapter.removeOne(action.id, state)
  ),
  on(ResearchActions.deleteResearchs, (state, action) =>
    researchAdapter.removeMany(action.ids, state)
  ),
  on(ResearchActions.loadResearchs, (state, action) =>
    researchAdapter.setAll(action.researchs, state)
  ),
  on(ResearchActions.setResearchs, (state, action) =>
    researchAdapter.setMany(action.researchs, state)
  ),
  on(ResearchActions.clearResearchs, state => researchAdapter.removeAll(state)),
  on(ngPatLogout, state => ({
    ...initialResearchState,
    ...researchAdapter.removeAll(state)
  })),
  on(ResearchActions.selectResearchID, (state, action) => {
    return {
      ...state,
      selectedResearchID: action.id
    };
  }),
  on(resetNavigation, (state: ResearchState) => {
    return {
      ...state,
      selectedResearchID: null
    };
  })
);
