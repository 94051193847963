import {ChangeDetectionStrategy, Component, input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'dlc-footer-bar',
  templateUrl: './dlc-footer-bar.component.html',
  styleUrls: ['./dlc-footer-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: `dlc-footer-bar`,
    '[class.dlc-footer-bar--border-upper-left-radius]': `borderUpperLeftRadius() == true`,
    '[class.dlc-footer-bar--border-upper-right-radius]': 'borderUpperRightRadius() == true'
  },
  standalone: true
})
export class DlcFooterBarComponent {
  borderUpperLeftRadius = input(true);
  borderUpperRightRadius = input(true);
}
