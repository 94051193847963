import {Injectable} from '@angular/core';
import {generate8CharCodeLowercase} from '@ngpat/fn';
import {selectNgPatUserAccount} from '@ngpat/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {EMPTY, forkJoin} from 'rxjs';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {
  updateFirestorePartialProject,
  updateProjectEffect,
  upsertProjects
} from './project.actions';
import {Project} from './project.model';
import {PartialProjectState} from './project.reducer';
import {ProjectService} from './project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectEffects {
  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _projectService
   */
  // deleteDoc$ = createEffect(() =>
  //   this._actions$.pipe(
  //     ofType(deleteProjectFromfirestore),
  //     withLatestFrom(this.store.select(selectUserAccount)),
  //     switchMap(([action, account]) =>
  //       this.store.pipe(
  //         select(getClassroomByID(action.id)),
  //         switchMap((classRoom: Classroom | undefined) =>
  //           this._projectService
  //             .deleteDoc$(classRoom, <string>account.uid)
  //             .pipe(map(() => deleteProject({id: action.id})))
  //         )
  //       )
  //     )
  //   )
  // );

  /**
   * Handled in entity list component
   * @param _actions$
   * @param store
   * @param _projectService
   */
  // deleteDocs$ = createEffect(
  //   () =>
  //     this._actions$.pipe(
  //       ofType(deleteProjects),
  //       withLatestFrom(this.store.select(selectUserAccount)),
  //       switchMap(([action, account]) =>
  //         this.store.pipe(
  //           select(getClassroomByID(action.ids[0])),
  //           switchMap((classRoom: Classroom | undefined) =>
  //             this._projectService.deleteDocs$(classRoom, action.ids, <string>account.uid)
  //           )
  //         )
  //       )
  //     ),
  //   {dispatch: false}
  // );

  updateFirestorePartialQuiz$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateFirestorePartialProject),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) =>
          this._projectService.updatePartialFirestore$(
            action.changes,
            action.project,
            account.uid
          )
        )
      ),
    {dispatch: false}
  );

  updateProject$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateProjectEffect),
        switchMap(action => {
          return this._projectService.updateDoc(action.project);
        })
      ),
    {dispatch: false}
  );

  updateProjectEffect$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(updateProjectEffect),
        switchMap(action => {
          return this._projectService.updateDoc(action.project);
        })
      ),
    {dispatch: false}
  );

  upsertProjects$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(upsertProjects),
        withLatestFrom(this.store.select(selectNgPatUserAccount)),
        switchMap(([action, account]) => {
          interface ProjectUpdate {
            changes: Partial<Project>;
            project: Project;
          }

          const _projectsUpdate: ProjectUpdate[] = action.projects.reduce(
            (a: ProjectUpdate[], s: Project) => {
              const changes: Partial<Project> = {};

              let update = false;

              if (!s.mentorCode) {
                changes.mentorCode = generate8CharCodeLowercase();
                update = true;
              }

              if (!s.teacherCode) {
                changes.teacherCode = generate8CharCodeLowercase();
                update = true;
              }

              if (!s.studentCode) {
                changes.studentCode = generate8CharCodeLowercase();
                update = true;
              }

              if (update) {
                a.push({
                  changes,
                  project: {...s}
                });
              }

              return a;
            },
            <ProjectUpdate[]>[]
          );

          if (_projectsUpdate.length) {
            return forkJoin(
              _projectsUpdate.map((s: ProjectUpdate) =>
                this._projectService.updatePartialFirestore$(
                  s.changes,
                  s.project,
                  account.uid
                )
              )
            );
          }

          return EMPTY;
        })
      ),
    {dispatch: false}
  );

  constructor(
    private _actions$: Actions,
    private store: Store<PartialProjectState>,
    private _projectService: ProjectService
  ) {}
}
