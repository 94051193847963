import {NgPatFirestoreService} from '@ngpat/firebase';
import {Store} from '@ngrx/store';
import firebase from 'firebase/compat';
import {
  DocumentData,
  onSnapshot,
  QueryDocumentSnapshot
} from 'firebase/firestore';

import {firestoreUserAccountDoc} from '../firebase';
import {upsertMember} from './members.actions';
import {Member} from './members.model';

import SnapshotOptions = firebase.firestore.SnapshotOptions;

// Firestore data converter
const userConverter = {
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<DocumentData>,
    options?: SnapshotOptions | undefined
  ): Member => {
    const data = <any>snapshot.data(options);

    let username = '';

    if (data.username && data.username.length) {
      username = data.username;
    } else if (data.displayName && data.displayName.length) {
      username = data.displayName;
    } else if (data.email && data.email.length) {
      // get part of email before @
      username = data.email.split('@')[0];
    } else {
      username = 'No Name';
    }

    return {
      entities: {},
      entityIDs: [],
      uid: data.uid,
      username
    };
  },
  toFirestore: (user: any) => {
    return {
      ...user
    };
  }
};

export class MemberQuery {
  private subscriptionsSub: (() => void) | undefined;

  constructor(
    private _member: Member,
    private customFirestoreService: NgPatFirestoreService,
    protected store: Store
  ) {
    this.onConnect();
  }

  onConnect() {
    if (this.subscriptionsSub) {
      this.subscriptionsSub();
    }

    this.subscriptionsSub = onSnapshot(
      this.customFirestoreService
        .docRef(firestoreUserAccountDoc(this._member.uid))
        .withConverter(userConverter),
      docSnap => {
        const member = docSnap.data();

        if (member && member.username && member.username.length) {
          (<Member>member).entityIDs = this._member.entityIDs;
          (<Member>member).entities = this._member.entities;

          this.store.dispatch(
            upsertMember({
              member
            })
          );
        }
      }
    );
  }

  addEntity(member: Member) {
    this.store.dispatch(
      upsertMember({
        member
      })
    );
  }

  onDisconnect() {
    if (this.subscriptionsSub) {
      this.subscriptionsSub();
    }
  }
}
