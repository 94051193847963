import {Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
    selector: 'dlc-pricing-header',
    imports: [CommonModule],
    templateUrl: './pricing-header.component.html',
    styleUrl: './pricing-header.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'dlc-pricing-header'
    }
})
export class PricingHeaderComponent {
  @Input() headerTitle = ``;
  @Input()
  headerSubtitle = ``;
}
