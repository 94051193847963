import {BASE_COLLECTION_NAME} from './model';

export function firestoreUserStudyGroupCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.STUDY_GROUPS}`;
}

export function firestoreUserStudyGroupDoc(uid: string, studyGroupID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}`;
}

export function firestoreStudyGroupCollection() {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}`;
}
export function firestoreStudyGroupDoc(studyGroupID: string): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}`;
}
export function firestoreStudyGroupChatTopicsCollection(studyGroupID: string): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreStudyGroupChatTopicsDoc(studyGroupID: string, chatTopicID: string): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreStudyGroupByIDQuizzesCollection(studyGroupID: string): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.QUIZZES}`;
}
