<dlc-label-container class="dlc-input-link--container">
  <span dlcLabel>
    <div class="dlc-input-link--container-label">
      <mat-icon class="mr-2">link</mat-icon>
      <span>Link</span>
    </div>
  </span>
  <div
    class="dlc-input-link--container-content"
    [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Label</mat-label>
      <input
        matInput
        placeholder="Name of link"
        formControlName="label"
        autocomplete="off" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>URL</mat-label>
      <input
        matInput
        placeholder="http://referencelink.com"
        formControlName="url"
        autocomplete="off" />
    </mat-form-field>

    <div class="flex flex-row justify-start items-center self-stretch">
      <button
        dlc-rounded-icon-button
        class="mr-4"
        (click)="deleteLink.emit()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>

    <div
      @expandEnterAnimation
      *ngIf="
        formGroup.errors?.['urlNeedsValue'] &&
        (!formGroup.untouched || formGroup.dirty)
      "
      class="dlc-input-link--container-error">
      URL is required.
    </div>
  </div>
</dlc-label-container>
