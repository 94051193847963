import {NavItemDict} from '../../../+ui/ui.model';
import {NAV} from '../nav-item-dict.model';

export const mobileNavItemDictDashboard: NavItemDict = {
  [NAV.DASHBOARD_ACCOUNT_PAGE]: {
    icon: 'account_circle',
    id: NAV.MOBILE_ACCOUNT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Account',
    navigateTo: ['dashboard', 'account'],
    paramKeys: [],
    previousNavItemID: NAV.DASHBOARD_ACCOUNT_PAGE,
    url: '/dashboard/account'
  },

  /**
   * Home page determined at:
   * libs/web-platform/data-access/src/lib/+ui/ui.selectors.ts:80
   */
  [NAV.DASHBOARD_CLASSES_PAGE]: {
    icon: 'school',
    id: NAV.DASHBOARD_CLASSES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'school',
    navigateTo: ['dashboard', 'classes'],
    paramKeys: [],
    previousNavItemID: NAV.DASHBOARD_CLASSES_PAGE,
    url: '/dashboard/classes'
  },

  [NAV.DASHBOARD_PERSONAL_QUIZZES_PAGE]: {
    icon: 'school',
    id: NAV.DASHBOARD_PERSONAL_QUIZZES_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'school',
    navigateTo: ['dashboard', 'personal-quizzes'],
    paramKeys: [],
    previousNavItemID: NAV.DASHBOARD_CLASSES_PAGE,
    url: '/dashboard/personal-quizzes'
  },

  [NAV.DASHBOARD_PRIVACY_POLICY_PAGE]: {
    icon: 'security',
    id: NAV.MOBILE_PRIVACY_POLICY_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Privacy Policy',
    navigateTo: ['dashboard', 'privacy-policy'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/dashboard/privacy-policy'
  },
  [NAV.DASHBOARD_PROFILE_PAGE]: {
    icon: 'account_circle',
    id: NAV.DASHBOARD_PROFILE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'Profile',
    navigateTo: ['dashboard', 'profile'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/dashboard/profile'
  },
  [NAV.DASHBOARD_STUDY_GROUP_PAGE]: {
    icon: 'school',
    id: NAV.DASHBOARD_STUDY_GROUP_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'school',
    navigateTo: ['dashboard', 'study-group'],
    paramKeys: [],
    previousNavItemID: NAV.DASHBOARD_STUDY_GROUP_PAGE,
    url: '/dashboard/study-group'
  },
  [NAV.DASHBOARD_STUDY_GROUPS_PAGE]: {
    icon: 'school',
    id: NAV.DASHBOARD_STUDY_GROUPS_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: true,
    name: 'school',
    navigateTo: ['dashboard', 'study-groups'],
    paramKeys: [],
    previousNavItemID: NAV.DASHBOARD_STUDY_GROUPS_PAGE,
    url: '/dashboard/study-groups'
  },
  [NAV.DASHBOARD_SUPPORT_PAGE]: {
    icon: 'support',
    id: NAV.DASHBOARD_SUPPORT_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Support',
    navigateTo: ['dashboard', 'support'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/dashboard/support'
  },
  [NAV.DASHBOARD_TERMS_OF_USE_PAGE]: {
    icon: 'policy',
    id: NAV.DASHBOARD_TERMS_OF_USE_PAGE,
    includeLogoForParent: false,
    includeTopNavForParent: false,
    name: 'Terms Of Use',
    navigateTo: ['dashboard', 'terms-of-use'],
    paramKeys: [],
    previousNavItemID: NAV.MOBILE_HOME_PAGE,
    url: '/dashboard/terms-of-use'
  }
};
