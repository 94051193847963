import {BASE_COLLECTION_NAME} from './model';

export function firestoreUserInvestigationCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.INVESTIGATIONS}`;
}

export function firestoreUserInvestigationDoc(
  uid: string,
  investigationID: string
): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationID}`;
}

export function firestoreInvestigationCollection() {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}`;
}
export function firestoreInvestigationDoc(investigationID: string): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationID}`;
}
export function firestoreInvestigationChatTopicsCollection(
  investigationID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreInvestigationChatTopicsDoc(
  investigationID: string,
  chatTopicID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreInvestigationByIDQuizzesCollection(
  investigationID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationID}/${BASE_COLLECTION_NAME.QUIZZES}`;
}
