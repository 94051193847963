import { createAction, props } from '@ngrx/store';

import { BaseEntityType } from './entity/entity.model';

/**
 * Should only be called by the app component.
 */
export const configurePlatform = createAction(
  '[Platform] Configure',
  props<{
    themes: string[];
    defaultProjectType: BaseEntityType;
    entityTypes: BaseEntityType[];
  }>()
);
