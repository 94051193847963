import {BASE_COLLECTION_NAME} from './model';

export function firestoreNoteVersionDoc(
  noteDocPath: string,
  versionID: string
): string {
  return `${noteDocPath}/${BASE_COLLECTION_NAME.NOTES_VERSIONS}/${versionID}`;
}

export function firestoreTranscribedNoteVersionDoc(
  noteDocPath: string,
  versionID: string
): string {
  return `${noteDocPath}/${BASE_COLLECTION_NAME.NOTE_TRANSCRIBED_VERSION}/${versionID}`;
}

export function firestoreStudyGroupNoteDoc(
  studyGroupID: string,
  noteID: string
): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.NOTES}/${noteID}`;
}

export function firestoreStudyGroupNoteCollection(
  studyGroupID: string
): string {
  return `${BASE_COLLECTION_NAME.STUDY_GROUPS}/${studyGroupID}/${BASE_COLLECTION_NAME.NOTES}`;
}

// CLASS NOTES
export function firestoreClassNoteDoc(classID: string, noteID: string): string {
  return `${BASE_COLLECTION_NAME.CLASSES}/${classID}/${BASE_COLLECTION_NAME.NOTES}/${noteID}`;
}

export function firestoreClassNoteCollection(classID: string): string {
  return `${BASE_COLLECTION_NAME.CLASSES}/${classID}/${BASE_COLLECTION_NAME.NOTES}`;
}

export function firestoreResearchNoteDoc(
  researchID: string,
  noteID: string
): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.NOTES}/${noteID}`;
}

export function firestoreResearchNoteCollection(researchID: string): string {
  return `${BASE_COLLECTION_NAME.RESEARCH}/${researchID}/${BASE_COLLECTION_NAME.NOTES}`;
}

export function firestoreInvestigationNoteDoc(
  investigationsID: string,
  noteID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationsID}/${BASE_COLLECTION_NAME.NOTES}/${noteID}`;
}

export function firestoreInvestigationNoteCollection(
  investigationsID: string
): string {
  return `${BASE_COLLECTION_NAME.INVESTIGATIONS}/${investigationsID}/${BASE_COLLECTION_NAME.NOTES}`;
}

export function firestoreUserNoteDoc(uid: string, noteID: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.NOTES}/${noteID}`;
}

export function firestoreUserNoteCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.NOTES}`;
}

export function firestoreNotesCollection(
  baseCollection: BASE_COLLECTION_NAME,
  parentId: string
) {
  if (baseCollection === BASE_COLLECTION_NAME.USERS) {
    return firestoreUserNoteCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.STUDY_GROUPS) {
    return firestoreStudyGroupNoteCollection(parentId);
  } else if (baseCollection === BASE_COLLECTION_NAME.CLASSES) {
    return firestoreClassNoteCollection(parentId);
  }

  return '';
}

export function firestoreNotesDoc(
  baseCollection: BASE_COLLECTION_NAME,
  parentId: string,
  noteID: string
) {
  if (baseCollection === BASE_COLLECTION_NAME.USERS) {
    return firestoreUserNoteDoc(parentId, noteID);
  } else if (baseCollection === BASE_COLLECTION_NAME.STUDY_GROUPS) {
    return firestoreStudyGroupNoteDoc(parentId, noteID);
  } else if (baseCollection === BASE_COLLECTION_NAME.CLASSES) {
    return firestoreClassNoteDoc(parentId, noteID);
  }

  return '';
}
