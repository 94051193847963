import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  Input,
  InputSignal,
  output,
  OutputEmitterRef,
  signal,
  ViewEncapsulation,
  WritableSignal
} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {NgPatNormalizedPrice, NgPatNormalizedPriceIntervalValueEnum} from '@ngpat/store';
// import { Store } from '@ngrx/store';
import {
  DlcTextItem,
  DlcTextToggleComponent
} from '../../toggle/text-toggle/dlc-text-toggle.component';
import {DlcStripeSubscriptionsComponent} from '../subscriptions-stripe/subscriptions/dlc-stripe-subscriptions.component';
import {FeatureListWithPriceComponent} from './feature-list/dlc-feature-list-with-price.component';
import {PriceToggleComponent} from './price-toggle/price-toggle.component';
import {PricingHeaderComponent} from './pricing-header/pricing-header.component';

/**
 * Ported from https://tailwindui.com/components/marketing/sections/pricing
 */

export const toggleItems: DlcTextItem[] = [
  {title: 'Monthly', value: 'monthly'},
  {title: 'Annually', value: 'annually'}
];
@Component({
    selector: 'dlc-pricing',
    imports: [
        CommonModule,
        PricingHeaderComponent,
        DlcTextToggleComponent,
        PriceToggleComponent,
        DlcStripeSubscriptionsComponent,
        MatDividerModule,
        PriceToggleComponent,
        FeatureListWithPriceComponent
    ],
    templateUrl: './dlc-pricing.component.html',
    styleUrl: './dlc-pricing.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'dlc-pricing'
    }
})
export class DlcPricingComponent {
  priceIntervals: DlcTextItem[] = toggleItems;
  selectedPriceInterval: WritableSignal<DlcTextItem> = signal(this.priceIntervals[0]);
  selectedPriceIntervalEnum: WritableSignal<NgPatNormalizedPriceIntervalValueEnum> = <
    WritableSignal<NgPatNormalizedPriceIntervalValueEnum>
  >signal(NgPatNormalizedPriceIntervalValueEnum.Monthly);
  pricesSig: WritableSignal<NgPatNormalizedPrice[]> = signal([]);

  pricesColumnsClassSig: WritableSignal<string> = <WritableSignal<string>>computed(() => {
    const prices = this.pricesSig();
    return `force-lg-grid-cols-${prices.length} ${
      prices.length > 1 ? 'lg:mx-0 lg:max-w-none' : ''
    }`;
  });

  // hasWebSubscription: Signal<boolean> = this.store.selectSignal(selectHasActiveStripeSubscription);
  // hasWebSubscription: InputSignal<boolean> = input(false);
  hasActiveSubscription: InputSignal<boolean> = input(false);

  doLogin: OutputEmitterRef<boolean> = output();

  // header
  showHeader = input(false);
  showIntervalToggle = input(false);
  headerTitle = input(``);
  headerSubtitle = input(``);
  showBothPricesAtSameTime = input(false);
  showFeatureDescription = input(false);

  @Input()
  set prices(value: NgPatNormalizedPrice[] | undefined | null) {
    console.log('prices', value);
    this.pricesSig.set(value || []);
  }

  showCheckoutProgressBar: OutputEmitterRef<boolean> = output();

  onIntervalChange(interval: DlcTextItem) {
    if (interval.value === 'monthly') {
      this.selectedPriceIntervalEnum.set(NgPatNormalizedPriceIntervalValueEnum.Monthly);
    } else {
      this.selectedPriceIntervalEnum.set(NgPatNormalizedPriceIntervalValueEnum.Annually);
    }
  }
}
