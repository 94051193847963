import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {ControlContainer, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {expandEnterAnimation} from '../../../animations';
import {DlcRoundedIconButtonComponent} from '../../button/dlc-rounded-icon-button/dlc-rounded-icon-button.component';
import {DlcLabelContainerComponent} from '../../containers/dlc-label-container/dlc-label-container.component';

@Component({
  animations: [expandEnterAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'dlc-input-link'
  },
  imports: [
    CommonModule,
    DlcLabelContainerComponent,
    DlcRoundedIconButtonComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule
  ],
  selector: 'dlc-input-link',
  styleUrls: ['./dlc-input-link.component.scss'],
  templateUrl: './dlc-input-link.component.html'
})
export class DlcInputLinkComponent {
  /**
   * Delete ReferenceLink
   */
  @Output() deleteLink: EventEmitter<void> = new EventEmitter<void>();

  /**
   * FormGroup for ReferenceLink
   */
  @Input() formGroup!: FormGroup;

  constructor(private controlContainer: ControlContainer) {}

  get text() {
    return this.formGroup.get('label');
  }
}
