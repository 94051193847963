import {BASE_COLLECTION_NAME} from './model';

export function firestoreUserProjectCollection(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.PROJECTS}`;
}

export function firestoreUserProjectDoc(
  uid: string,
  projectID: string
): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}/${BASE_COLLECTION_NAME.PROJECTS}/${projectID}`;
}

export function firestoreProjectCollection() {
  return `${BASE_COLLECTION_NAME.PROJECTS}`;
}
export function firestoreProjectDoc(projectID: string): string {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectID}`;
}
export function firestoreProjectChatTopicsCollection(
  projectID: string
): string {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}`;
}
export function firestoreProjectChatTopicsDoc(
  projectID: string,
  chatTopicID: string
): string {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectID}/${BASE_COLLECTION_NAME.CHAT_TOPICS}/${chatTopicID}`;
}

export function firestoreProjectByIDQuizzesCollection(
  projectID: string
): string {
  return `${BASE_COLLECTION_NAME.PROJECTS}/${projectID}/${BASE_COLLECTION_NAME.QUIZZES}`;
}
