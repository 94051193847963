import {inject, Injectable} from '@angular/core';
import {firestoreTranscribedNoteVersionDoc} from '@gigasoftware/evolving-cognition/domain';
import {
  GigaNoteImageState,
  GigaNoteTranscriptionState,
  GigaUserNoteVersion,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {jsonPopulated} from '@ngpat/rxjs';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {
  DlcNoteProcessQueue,
  DlcNoteSaveAction,
  DlcNoteTranscribedTextId,
  DlcNoteTranscribedTextIdNull
} from './note.model';
import {ofDlcNoteActionTypeWithTranscription} from './note.fns';
import {DlcNoteStoreService} from './note.store';

@Injectable()
export class DlcTranscribeNoteService {
  private _onDestroy$: Subject<boolean> = new Subject();

  private store = inject(DlcNoteStoreService);
  firestore: NgPatFirestoreService = inject(NgPatFirestoreService);

  constructor() {
    const that = this;

    // GET LATEST VERSION OF TRANSCRIBED NOTE
    this.store.selectTranscribedTextId$
      .pipe(
        takeUntil(this._onDestroy$),
        filter(
          (doc: DlcNoteTranscribedTextIdNull) =>
            (doc && doc.firestorePath && doc.transcribedTextId.length > 0) as boolean
        ),
        map((doc: DlcNoteTranscribedTextIdNull) => {
          return doc as DlcNoteTranscribedTextId;
        }),
        map((doc: DlcNoteTranscribedTextId) =>
          firestoreTranscribedNoteVersionDoc(doc.firestorePath, doc.transcribedTextId)
        ),
        this.firestore.onSnapshotDoc$<GigaUserNoteVersion | null | undefined>(),
        // tap(note => {
        //   console.log(note);
        // }),
        jsonPopulated<GigaUserNoteVersion>(),
        map((doc: GigaUserNoteVersion) => doc.text)
      )
      .subscribe((doc: string) => {
        this.store.updateLatestTranscriptionVersion(doc);
      });

    this.store.saveDataProcessQueue.currentItem$
      .pipe(
        takeUntil(this._onDestroy$),
        ofDlcNoteActionTypeWithTranscription(DlcNoteSaveAction.SAVE_TRANSCRIPTION_NOTE_VERSION)
      )
      .subscribe(async (action: DlcNoteProcessQueue) => {
        if (action.transcriptionNoteVersion) {
          await this.store.upsertTranscribedVersionPromise(action.transcriptionNoteVersion);
          await that.store.saveDataProcessQueue.next(action.id);
        }
      });
  }

  init() {
    // console.log('init transcribe note service');
  }

  async onImageUploadStart(pathOfImage: string) {
    // console.log('onImageUploadStart', pathOfImage);
    await this.store.updateFirestoreDocPromise({
      // imagePath: pathOfImage,
      imageState: GigaNoteImageState.PENDING
    });
  }

  onDeleteImage() {
    // TODO: update firestore doc
  }

  // TODO move to store, update image path to firestore doc
  async onUploadComplete(pathOfImage: string) {
    await this.store.updateFirestoreDocPromise({
      imagePath: pathOfImage,
      imageState: GigaNoteImageState.UPLOADED
    });
  }

  async doTranscribeImage() {
    this.store.doTranscribeImage({
      transcribeState: GigaNoteTranscriptionState.IN_PROGRESS
    });
  }

  onDestroy() {
    // console.log('destroying transcribe note service');
    this._onDestroy$.next(true);
    this._onDestroy$.complete;
  }
}
