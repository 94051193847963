import {BASE_COLLECTION_NAME} from './model';

/**
 * User paths
 */
export function firestoreUserCollection() {
  return `${BASE_COLLECTION_NAME.USERS}`;
}

export function firestoreUserAccountDoc(uid: string): string {
  return `${BASE_COLLECTION_NAME.USERS}/${uid}`;
}
