import {NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {ACTIVE_STATUS} from '@ngpat/store';
import {WINDOW} from '@ngpat/utils';
import {DlcRoundedTextButtonComponent} from '../../components/button/dlc-rounded-text-button/dlc-rounded-text-button.component';

export interface PresenceID {
  id: ACTIVE_STATUS;
}

@Component({
    selector: 'dlc-app-idle',
    templateUrl: './app-idle.component.html',
    styleUrls: ['./app-idle.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, MatDialogModule, MatButtonModule, DlcRoundedTextButtonComponent]
})
export class AppIdleComponent {
  dialogID = ACTIVE_STATUS;

  constructor(
    private _dialogRef: MatDialogRef<AppIdleComponent>,
    @Inject(WINDOW) private _win: Window,
    @Inject(MAT_DIALOG_DATA) public data: PresenceID
  ) {}

  onClose() {
    this._win.location.reload();
    this._dialogRef.close();
  }
}
