import {EntityMap, EntityMapOne, Update} from '@ngrx/entity';
import {createAction, props} from '@ngrx/store';

import {Research} from './research.model';

export const addResearch = createAction(
  '[Research/API] Add Research',
  props<{research: Research}>()
);

export const setResearch = createAction(
  '[Research/API] Set Research',
  props<{research: Research}>()
);

export const upsertResearch = createAction(
  '[Research/API] Upsert Research',
  props<{research: Research}>()
);

export const addResearchs = createAction(
  '[Research/API] Add Researchs',
  props<{researchs: Research[]}>()
);

export const upsertResearchs = createAction(
  '[Research/API] Upsert Researchs',
  props<{researchs: Research[]}>()
);

export const updateResearch = createAction(
  '[Research/API] Update Research',
  props<{research: Update<Research>}>()
);

export const updateResearchEffect = createAction(
  '[Research/API] Update Research Effect',
  props<{research: Research}>()
);

export const updateResearchs = createAction(
  '[Research/API] Update Researchs',
  props<{researchs: Update<Research>[]}>()
);

export const mapResearch = createAction(
  '[Research/API] Map Research',
  props<{entityMap: EntityMapOne<Research>}>()
);

export const mapResearchs = createAction(
  '[Research/API] Map Researchs',
  props<{entityMap: EntityMap<Research>}>()
);

export const deleteResearch = createAction(
  '[Research/API] Delete Research',
  props<{id: string}>()
);

export const deleteResearchs = createAction(
  '[Research/API] Delete Researchs',
  props<{ids: string[]}>()
);
export const deleteResearchFromfirestore = createAction(
  '[Research/API] Delete Study Group From Firestore',
  props<{id: string}>()
);

export const updateFirestorePartialResearch = createAction(
  '[Research/API] Save Partial Research',
  props<{changes: Partial<Research>; research: Research}>()
);

export const loadResearchs = createAction(
  '[Research/API] Load Researchs',
  props<{researchs: Research[]}>()
);

export const setResearchs = createAction(
  '[Research/API] Set Researchs',
  props<{researchs: Research[]}>()
);

export const clearResearchs = createAction('[Research/API] Clear Researchs');

export const selectResearchID = createAction(
  '[Research/API] Select Research',
  props<{id: string}>()
);
