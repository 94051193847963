import {
  NgPatRemoteConfigEntity,
  selectHasActiveStripeSubscription,
  selectNgPatRemoteConfigEntities
} from '@ngpat/store';
import {Dictionary} from '@ngrx/entity';
import {createSelector} from '@ngrx/store';

import {Quiz} from '../+quizzes/quiz.model';
import {
  selectAllClassroomQuizzes,
  selectAllPersonalQuizzes,
  selectAllStudyGroupQuizzes
} from '../+quizzes/quiz.selectors';
import {inAppSubscriptionUserIsStudent} from './appstore-in-app-purchase.selectors';

export const selectHasActiveSubscription = createSelector(
  selectHasActiveStripeSubscription,
  inAppSubscriptionUserIsStudent,
  (stripeHasSubscription: boolean, appHasSubscription: boolean): boolean =>
    stripeHasSubscription || appHasSubscription
);

export const selectNotHasActiveSubscription = createSelector(
  selectHasActiveSubscription,
  (hasActiveSubscription: boolean): boolean => !hasActiveSubscription
);

// PERMISSIONS

export const selectCanCreatePersonalQuiz = createSelector(
  selectHasActiveSubscription,
  selectNgPatRemoteConfigEntities,
  selectAllPersonalQuizzes,
  (
    hasSubscription: boolean,
    entities: Dictionary<NgPatRemoteConfigEntity>,
    quizzes: Quiz[]
  ): boolean => {
    return hasSubscription;
  }
);

/**
 * @use selectDisableCreatePersonalQuiz$
 *
 * @description Returns true if the user does not an active student subscription
 * and has not exceeded the max number of quizzes for the subscription
 */
export const selectDisableCreatePersonalQuiz = createSelector(
  selectCanCreatePersonalQuiz,
  (canCreatePersonalQuiz: boolean): boolean => {
    return !canCreatePersonalQuiz;
  }
);

export const selectCanCreateStudyGroupQuiz = createSelector(
  selectHasActiveSubscription,
  selectNgPatRemoteConfigEntities,
  selectAllStudyGroupQuizzes,
  (
    hasSubscription: boolean,
    entities: Dictionary<NgPatRemoteConfigEntity>,
    quizzes: Quiz[]
  ): boolean => {
    return hasSubscription;
  }
);

/**
 * @use selectDisableCreateStudyGroupQuiz$
 * @description Returns true if the user has an active subscription
 */
export const selectDisableCreateStudyGroupQuiz = createSelector(
  selectCanCreateStudyGroupQuiz,
  (canCreateStudyGroupQuiz: boolean): boolean => {
    return !canCreateStudyGroupQuiz;
  }
);

export const selectCanCreateClassQuiz = createSelector(
  selectHasActiveSubscription,
  selectNgPatRemoteConfigEntities,
  selectAllClassroomQuizzes,
  (
    hasTeacherSubscription: boolean,
    entities: Dictionary<NgPatRemoteConfigEntity>,
    quizzes: Quiz[]
  ): boolean => {
    return hasTeacherSubscription;
  }
);

export const selectDisableCreateClassQuiz = createSelector(
  selectCanCreateClassQuiz,
  (canCreateClassQuiz: boolean): boolean => {
    return !canCreateClassQuiz;
  }
);
