import {Injectable} from '@angular/core';
import {
  Exists,
  NgPatFirestoreCollectionQuery,
  ngPatFirestoreCollectionQueryFactory,
  NgPatFirestoreService
} from '@ngpat/firebase';
import {
  aggregateUpdates,
  NgPatAccountState,
  NgPatFirebaseConnectionService,
  NgPatServiceConnector
} from '@ngpat/store';
import {Store} from '@ngrx/store';
import {User} from 'firebase/auth';
import {Observable, of} from 'rxjs';
import {map, switchMap, take} from 'rxjs/operators';

import {deleteQuizs, updateQuizs, upsertQuizs} from '../+quizzes/quiz.actions';
import {Quiz} from '../+quizzes/quiz.model';
import {
  assignDeprecatedBaseEntitiesProperties,
  BaseEntity
} from '../entity/entity.model';
import {
  firestoreQueryPathByEntity,
  firestoreQuizCollection,
  firestoreProjectCollection,
  firestoreUserProjectCollection
} from '../firebase/database-paths';
import {QueryEngineCache} from '../services/query-engine-cache';
import {
  deleteProjects,
  updateProjects,
  upsertProjects
} from './project.actions';
import {Project} from './project.model';
import {projectFeatureKey} from './project.reducer';
import {selectAllProjects} from './project.selectors';

@Injectable({
  providedIn: 'root'
})
export class ProjectService implements NgPatFirebaseConnectionService {
  private _projectQuizQueryCache!: QueryEngineCache<Quiz>;
  private _queryCollaborationService!: NgPatFirestoreCollectionQuery<Project>;
  private _queryPrivateQuizService!: NgPatFirestoreCollectionQuery<Project>;
  connection: NgPatServiceConnector = new NgPatServiceConnector(
    this,
    this.store
  );
  connectionKey = projectFeatureKey;

  constructor(
    private customFirestoreService: NgPatFirestoreService,
    private store: Store
  ) {}

  deleteDoc$(classroom: BaseEntity | undefined, uid: string) {
    if (classroom) {
      const path: string = classroom.isPrivate
        ? firestoreUserProjectCollection(uid)
        : firestoreProjectCollection();

      // const path = project.isPrivate ?
      return this.customFirestoreService.deleteDoc$(path);
    }
    return of(true);
  }

  deleteDocs$(classroom: BaseEntity | undefined, ids: string[], uid: string) {
    if (classroom) {
      const isPrivate = classroom.isPrivate;
      const path: string = isPrivate
        ? firestoreUserProjectCollection(uid)
        : firestoreProjectCollection();
      return this.customFirestoreService.deleteDocs$(path, ids);
    }

    return of(true);
  }

  ngPatOnInit() {
    this._queryCollaborationService =
      new NgPatFirestoreCollectionQuery<Project>(
        {
          deleteManyAction: (ids: string[]) => deleteProjects({ids}),
          queryMember: true,
          updateManyAction: (projects: Project[]) =>
            updateProjects({
              projects: aggregateUpdates(
                assignDeprecatedBaseEntitiesProperties(projects) as Project[]
              )
            }),
          upsertManyAction: (projects: Project[]) =>
            upsertProjects({
              projects: assignDeprecatedBaseEntitiesProperties(
                projects
              ) as Project[]
            })
        },
        this.store,
        this.customFirestoreService
      );

    // TODO Not needed
    const queryProjectConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteProjects({ids}),
        queryMember: false,
        updateManyAction: (projects: Project[]) =>
          updateProjects({
            projects: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(projects) as Project[]
            )
          }),
        upsertManyAction: (projects: Project[]) =>
          upsertProjects({
            projects: assignDeprecatedBaseEntitiesProperties(
              projects
            ) as Project[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    this._queryPrivateQuizService =
      queryProjectConfig.createFirestoreCollectionQuery();

    const queryQuizConfig = ngPatFirestoreCollectionQueryFactory(
      {
        deleteManyAction: (ids: string[]) => deleteQuizs({ids}),
        queryMember: false,
        updateManyAction: (quizs: Quiz[]) =>
          updateQuizs({
            quizs: aggregateUpdates(
              assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
            )
          }),
        upsertManyAction: (quizs: Quiz[]) =>
          upsertQuizs({
            quizs: assignDeprecatedBaseEntitiesProperties(quizs) as Quiz[]
          })
      },
      this.store,
      this.customFirestoreService
    );

    const quizPathGenerator = (entity: Quiz, uid: string) =>
      `${firestoreQueryPathByEntity(entity as BaseEntity, uid)}/${firestoreQuizCollection()}`;

    this._projectQuizQueryCache = new QueryEngineCache<Quiz>(
      queryQuizConfig,
      this.store,
      selectAllProjects,
      quizPathGenerator,
      'id'
    );
  }

  onConnect(user: NgPatAccountState) {
    // implement query
    this._queryCollaborationService.onConnect(
      firestoreProjectCollection(),
      null,
      <string>user.uid
    );
    this._queryPrivateQuizService.onConnect(
      firestoreUserProjectCollection(<string>user.uid),
      null,
      null
    );
    this._projectQuizQueryCache.onConnect(user);
  }

  onDisconnect(user: NgPatAccountState) {
    // Unsubscribe to query
    this._queryCollaborationService.onDisconnect();
    this._queryPrivateQuizService.onDisconnect();
    this._projectQuizQueryCache.onDisconnect();

    // Unsubscribe to query before calling this
  }

  updateDoc(g: Project) {
    return this.customFirestoreService.user$.pipe(
      take(1),
      switchMap((u: User) => {
        const path = firestoreQueryPathByEntity(g, <string>u.uid);
        return this.customFirestoreService.merge$(path, g);
      })
    );
  }

  updatePartialFirestore$(
    changes: Partial<Project>,
    quiz: Project,
    uid: string | null
  ): Observable<Project> {
    if (uid) {
      return this.customFirestoreService
        .merge$<Project>(firestoreQueryPathByEntity(quiz, uid), changes)
        .pipe(map((r: Exists<Project>) => r.data));
    }

    return of(quiz);
  }
}
