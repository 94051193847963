import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';

import {fadeInAnimation} from '../../../animations';

@Component({
  animations: [fadeInAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[@fadeInAnimation]': '',
    class: 'dlc-sidenav-content'
  },
  imports: [CommonModule],
  selector: 'dlc-sidenav-content',
  styleUrl: './dlc-sidenav-content.component.scss',
  templateUrl: './dlc-sidenav-content.component.html'
})
export class DlcSidenavContentComponent {
  bottom = input('0');
}
